import { useEffect, useState } from 'react';
import { fetchEmojiShortcodes } from '../utils';

export const useEmojiShortcodes = () => {
    const [shortcodes, setShortcodes] = useState<Record<string, string>>({});

    useEffect(() => {
        (async () => {
            setShortcodes(await fetchEmojiShortcodes());
        })();
    }, []);

    return shortcodes;
};
