import { useEffect, useState } from 'react';
import {
    Emoji,
    getGroupedEmojiList,
} from '../../../../../EmojiPicker/grouped-emojis';

export function useGroupedEmojiList() {
    const [groupedEmojiList, setGroupedEmojiList] = useState<
        Record<number, Emoji[]>
    >({});

    useEffect(() => {
        (async () => {
            setGroupedEmojiList(await getGroupedEmojiList());
        })();
    }, []);

    return groupedEmojiList;
}
