import { BulbIcon } from './icons/BulbIcon';
import { ForkKnifeIcon } from './icons/ForkKnifeIcon';
import { NatureIcon } from './icons/NatureIcon';
import { SearchIcon } from './icons/SearchIcon';
import { SmileIcon } from './icons/SmileIcon';
import { TennisIcon } from './icons/TennisIcon';
import { TrainIcon } from './icons/TrainIcon';
import { UserIcon } from './icons/UserIcon';
import { SymbolIcon } from './icons/SymbolIcon';
import { createElement, ElementType, FunctionComponentElement } from 'react';
import { FlagIcon } from './icons/FlagIcon';
import { EmojiTab, EmojiWithImages } from './types';
import { WhatsAppIcon } from './icons/WhatsAppIcon';
import { RCSIcon } from './icons/RCSIcon';
import { SvgIconProps } from '@mui/material';

export const ALL_GROUP = -1;

export const imagesMap: Record<
    string,
    FunctionComponentElement<SvgIconProps>
> = {
    image_whatsapp: createElement(WhatsAppIcon),
    image_rcs: createElement(RCSIcon),
};

export const imagesOptionsMap: Record<string, ElementType<SvgIconProps>> = {
    image_whatsapp: WhatsAppIcon,
    image_rcs: RCSIcon,
};

export const appsEmoji: EmojiWithImages[] = [
    {
        label: 'WhatsApp',
        hexcode: '',
        order: 0,
        group: 0,
        unicode: '',
        imageName: 'image_whatsapp',
    },
    {
        label: 'RCS',
        hexcode: '',
        order: 0,
        group: 0,
        unicode: '',
        imageName: 'image_rcs',
    },
];

export const emojiPickerTabs: EmojiTab[] = [
    {
        groupKey: ALL_GROUP,
        groupName: '',
        groupEmoji: createElement(SearchIcon),
    },
    {
        groupKey: 0,
        groupName: 'Smileys & Emotion',
        groupEmoji: createElement(SmileIcon),
    },
    {
        groupKey: 1,
        groupName: 'People & Body',
        groupEmoji: createElement(UserIcon),
    },
    {
        groupKey: 3,
        groupName: 'Animals & Nature',
        groupEmoji: createElement(NatureIcon),
    },
    {
        groupKey: 4,
        groupName: 'Food & Drink',
        groupEmoji: createElement(ForkKnifeIcon),
    },
    {
        groupKey: 5,
        groupName: 'Travel & Places',
        groupEmoji: createElement(TrainIcon),
    },
    {
        groupKey: 6,
        groupName: 'Activities',
        groupEmoji: createElement(TennisIcon),
    },
    { groupKey: 7, groupName: 'Objects', groupEmoji: createElement(BulbIcon) },
    {
        groupKey: 8,
        groupName: 'Symbols',
        groupEmoji: createElement(SymbolIcon),
    },
    { groupKey: 9, groupName: 'Flags', groupEmoji: createElement(FlagIcon) },
];
