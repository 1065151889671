import cc from 'classcat';
import styles from './ContactPanel.module.scss';
import { ContactPanelHeader } from './ContactPanelHeader';
import { ContactPanelInfo } from './ContactPanelInfo';
import { useContactQuery } from '../../queries/contacts';
import { Box } from '@mui/material';
import { root } from './styles';
import { Contact } from '../../api/types';
import { ContactPropertiesPanel } from '../ContactPropertiesPanel/ContactPropertiesPanel';
import {
    useByPhone,
    usePlaceholder,
} from '../../components/Contacts/use-contact-by-phone';

interface Props {
    className?: string;
    contactIdFromPathOrContact?: string;
    contactPhone?: string;
    onBack?: () => void;
    onCreate?: (c: Contact) => void;
}

export function ContactPanel({
    contactIdFromPathOrContact,
    contactPhone = '',
    className,
    onBack,
    onCreate,
}: Readonly<Props>) {
    const placeholder = usePlaceholder(contactPhone);
    const contactId =
        contactIdFromPathOrContact === 'new'
            ? undefined
            : contactIdFromPathOrContact;
    const { data: byPhone } = useByPhone(contactPhone);
    const cached =
        contactIdFromPathOrContact === 'new'
            ? placeholder
            : (byPhone ?? placeholder);

    const contact = useContactQuery(contactId ?? byPhone?.id).data;

    return (
        <Box
            component="section"
            sx={root}
            className={cc([styles['root'], className])}
        >
            <ContactPanelHeader onBack={onBack} />

            <ContactPanelInfo contact={contact ?? cached} onCreate={onCreate} />

            <ContactPropertiesPanel
                contact={contact ?? cached}
                onCreate={onCreate}
            />
        </Box>
    );
}
