import {
    Autocomplete,
    AutocompletePropsSizeOverrides,
    CircularProgress,
    SxProps,
    TextField,
    Theme,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { useCohorts } from '../../../queries/cohorts';
import { CohortMeta } from '../../../api/types';
import * as React from 'react';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';

type Props = {
    renderOption?: (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: CohortMeta,
        state: AutocompleteRenderOptionState,
    ) => React.ReactNode;
    placeholder?: string;
    disabled?: boolean;
    size?: OverridableStringUnion<
        'small' | 'medium',
        AutocompletePropsSizeOverrides
    >;
    selected?: CohortMeta;
    onChange: (c?: CohortMeta | undefined) => void;
    sx?: SxProps<Theme>;
};
export const CohortAutocomplete = (props: Props) => {
    const { data = [], isLoading } = useCohorts();

    return (
        <Autocomplete
            size={props.size}
            sx={props.sx}
            disabled={isLoading}
            value={props.selected ?? null}
            onChange={(_, value: CohortMeta | null) =>
                props.onChange(value ? value : undefined)
            }
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            popupIcon={
                isLoading ? (
                    <CircularProgress color="info" size={20} />
                ) : undefined
            }
            options={data}
            getOptionLabel={({ name }) => name}
            getOptionKey={({ id }) => id}
            aria-label="Lists filter"
            renderOption={props.renderOption}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder ?? 'All lists'}
                />
            )}
        />
    );
};
