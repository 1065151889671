import { useMemo, useState } from 'react';
import {
    Contact,
    IntegrationSource,
    NewContactProperty,
} from '../../../api/types';
import { Box, Link, Popover, Stack } from '@mui/material';
import { Add } from '../../ConversationsList/CAdd';
import { getSquare } from '../../../theme/style.helpers';
import { useMenu } from '../../../hooks/use-menu';
import { UserPropertyModel } from '../../UserInfoProperty/types';
import { PropertiesList } from './PropertiesList';
import { NewPropertyConfiguration } from './NewPropertyConfiguration';
import { useContactProperties } from './use-contact-properties';
import { availableIntegrations } from '../../../main/settings/Integrations/integrations-list';

type Props = {
    contact: Partial<Contact>;
    onAddProperty?: (newProp: UserPropertyModel) => void;
};

export const AddNewProperty = ({ contact, onAddProperty }: Props) => {
    const menu = useMenu();
    const handleClose = () => {
        menu.handleClose();
        window.setTimeout(() => {
            setSelectedProperty(null);
            setSelectedSource(null);
        }, 200);
    };

    const [selectedProperty, setSelectedProperty] =
        useState<NewContactProperty | null>(null);

    const [selectedSource, setSelectedSource] =
        useState<IntegrationSource | null>(null);

    const { properties, isLoading } = useContactProperties({
        source: selectedSource,
        contact,
    });
    const sourceName = useMemo(
        () =>
            availableIntegrations.find(
                (integration) => selectedSource === integration.source,
            )?.name ?? '',
        [selectedSource],
    );

    return (
        <Box px={4}>
            <Box py={2}>
                <Link
                    onClick={menu.handleOpen}
                    component={Stack}
                    direction="row"
                    alignItems="center"
                    gap={4}
                >
                    <Add sx={getSquare(20)} />
                    Add a new property
                </Link>
            </Box>
            <Popover
                open={menu.open}
                anchorEl={menu.anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        elevation: 5,
                        sx: {
                            borderRadius: 1.5,
                            margin: 0,
                            border: '1px solid',
                            borderColor: 'custom.gray.divider',
                            width: 240,
                        },
                    },
                }}
            >
                {selectedProperty ? (
                    <NewPropertyConfiguration
                        contact={contact}
                        handleClose={handleClose}
                        selectedProperty={selectedProperty}
                        handlePropertyClean={() => setSelectedProperty(null)}
                        onAddProperty={onAddProperty}
                    />
                ) : (
                    <PropertiesList
                        isLoading={isLoading}
                        properties={properties}
                        sourceName={sourceName}
                        onSelectProperty={(item) => {
                            const hasType = 'type' in item;

                            if ('source' in item && item.source && !hasType) {
                                setSelectedSource(item.source);

                                if (hasType) {
                                    setSelectedProperty(item);
                                }
                                return;
                            }

                            if (hasType) {
                                setSelectedProperty(item);
                            }
                        }}
                    />
                )}
            </Popover>
        </Box>
    );
};
