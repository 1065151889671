import { Divider, Link, Stack, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { UploadColoredIcon } from '../../../icons/common/UploadColoredIcon';
import { useCallback } from 'react';
import { colors } from '../../../theme/palette';
import { useCsvImportContext } from './CsvImportContext';

const MAX_FILE_SIZE = 50242880;

export const CsvFilePicker = () => {
    const { file, setFile } = useCsvImportContext();
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFile(acceptedFiles[0]);
        },
        [setFile],
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        maxFiles: 1,
        maxSize: MAX_FILE_SIZE,
        onDrop,
        accept: {
            'text/*': ['.csv'],
        },
    });

    if (file) {
        return null;
    }

    return (
        <>
            <Stack
                {...getRootProps()}
                spacing={2}
                sx={{
                    px: 6,
                    py: 5.5,
                    alignItems: 'center',
                    backgroundColor: isDragActive
                        ? colors.blue[50]
                        : 'custom.gray.superLight',
                    border: '1px dashed',
                    borderColor: isDragActive
                        ? 'info.main'
                        : 'custom.gray.light400',
                    borderRadius: 1.5,
                }}
            >
                <input {...getInputProps()} />
                <UploadColoredIcon width={40} height={40} />
                <Typography variant="body3" color="custom.gray.super">
                    Drag & drop a .csv file here or <Link>choose file</Link>
                </Typography>
            </Stack>
            <Divider />
        </>
    );
};
