import React, {
    createElement,
    ElementType,
    FunctionComponentElement,
} from 'react';
import { Inbox, InboxProvider } from '../api/types';
import { UUID } from '../types/uuid';
import { formatPhoneNumber } from './phoneNumber';
import { WhatsAppIcon } from '../components/EmojiPicker/icons/WhatsAppIcon';
import { SvgIconProps } from '@mui/material';
import { RCSIcon } from '../components/EmojiPicker/icons/RCSIcon';
import {
    imagesMap,
    imagesOptionsMap,
} from '../components/EmojiPicker/constants';
import { getEmojiComponent } from './get-emoji-component';

const getDefaultIcon = (provider?: InboxProvider) => {
    switch (provider) {
        case InboxProvider.WHATSAPP:
            return createElement(WhatsAppIcon);
        case InboxProvider.RCS:
            return createElement(RCSIcon);
        default:
            return '📱';
    }
};

const getOptionDefaultIcon = (provider?: InboxProvider): React.ElementType => {
    switch (provider) {
        case InboxProvider.WHATSAPP:
            return WhatsAppIcon;
        case InboxProvider.RCS:
            return RCSIcon;
        default:
            return getEmojiComponent('📱');
    }
};

export const renderInboxIcon = ({
    icon,
    provider,
}: {
    icon?: string;
    provider?: InboxProvider;
}) => {
    if (!icon) {
        return getDefaultIcon(provider);
    }
    let emoji: string | FunctionComponentElement<SvgIconProps>;

    if (icon.startsWith('image_')) {
        return imagesMap[icon];
    }

    try {
        emoji = String.fromCodePoint(parseInt(`0x${icon}`));
    } catch (e) {
        emoji = getDefaultIcon(provider);
    }
    return emoji;
};

export const renderInboxSelectIcon = ({
    icon,
    provider,
}: {
    icon?: string;
    provider?: InboxProvider;
}): ElementType | undefined => {
    if (!icon) {
        return getOptionDefaultIcon(provider);
    }

    if (icon.startsWith('image_')) {
        return imagesOptionsMap[icon];
    }

    try {
        const emoji = String.fromCodePoint(parseInt(`0x${icon}`));
        return getEmojiComponent(emoji);
    } catch (e) {
        return getOptionDefaultIcon(provider);
    }
};

export const renderInboxName = (inbox: Inbox) =>
    inbox.name || formatPhoneNumber(inbox.phone);

export const sortInboxesByPredefinedOrder = (
    inboxes: Inbox[],
    order: UUID[],
) => {
    const result = new Array(order.length);

    inboxes.forEach((inbox) => {
        const idx = order.indexOf(inbox.id);
        if (idx === -1) {
            result.push(inbox);
        } else {
            result[idx] = inbox;
        }
    });

    // If there are any ids of not anymore existing inboxes
    // we need to filter out that gaps from array
    return result.filter((x) => x);
};
