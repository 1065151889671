import { useMutation } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { TEAMMATES_KEY } from './user';
import { Role, RoleTypes } from '../api/types';
import { client } from '../api/http';

export const useUpdateRole = () =>
    useMutation({
        mutationFn: ({
            userId,
            role,
        }: {
            userId: string;
            role: RoleTypes;
        }): Promise<Role> =>
            client
                .post<Role>('/roles/updateForUser', { userId, role })
                .then(({ data }) => data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [TEAMMATES_KEY] });
        },
    });
