import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Icon } from '../../../icons/Icon';
import { getPropertyIcon } from '../../../utils/contact-properties';
import { PropertyIcon } from '../../../icons/iconsMap';
import { useMemo } from 'react';
import { EnhancedSelect } from '../../../components/EnhancedSelect/EnhancedSelect';
import { COL_WIDTH, usePreviewFormatter } from './use-preview-formatter';
import { getSquare } from '../../../theme/style.helpers';
import { getColId, useCsvImportContext } from './CsvImportContext';
import { useFilterMeta } from '../../../components/Contacts/Cohorts/hooks';
import { UserPropertyModel } from '../../../components/UserInfoProperty/types';
import { Feature, ItemType } from '../../../api/types';
import { useEnabledFeature } from '../../../queries/user';
import { StarFilledIcon } from '../../../icons/common/StarFilledIcon';
import { StarIcon } from '../../../icons/common/StarIcon';

export type ExtendedUserPropertyModel = {
    icon?: React.ReactNode | PropertyIcon;
    itemType?: ItemType;
    isPrimary?: boolean;
} & UserPropertyModel;

type Props = {
    property: ExtendedUserPropertyModel;
};

export const PropertyRow = ({ property }: Props) => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const { enabled, columns, preview, updateColumns, setPrimaryPhone } =
        useCsvImportContext();
    const previewFormatter = usePreviewFormatter();
    const getFilterMeta = useFilterMeta();
    const filterMeta = getFilterMeta({
        type: property.type,
        icon: property.icon,
    });

    const valueColumn = useMemo(
        () =>
            columns.find((col) => {
                if (!col.itemType || col.usage === 'skip') {
                    return false;
                }

                const isFieldSelected =
                    ['field', 'phone'].includes(col.itemType) &&
                    col.field &&
                    col.field === property.id;

                const isPropertySelected =
                    col.itemType === 'property' &&
                    col.propertyName &&
                    col.propertyName === property.name;

                return isFieldSelected || isPropertySelected;
            }),
        [columns, property.id, property.name],
    );

    const valuePreview = useMemo(() => {
        const colId = columns.find((col) => {
            if (!col.itemType) {
                return false;
            }

            if (['field', 'phone'].includes(col.itemType) && col.field) {
                return col.field === property.id;
            }

            if (col.itemType === 'property' && col.propertyName) {
                return col.propertyName === property.name;
            }

            return null;
        })?.id;

        return colId ? preview[colId] : undefined;
    }, [property, preview, columns]);

    const formattedValue = previewFormatter(
        valuePreview ?? '',
        property.id,
        filterMeta.type,
    );

    return (
        <Stack direction="row" alignItems="center" spacing={6}>
            <Stack
                direction="row"
                alignItems="center"
                flex={`0 0 ${COL_WIDTH}px`}
                spacing={4}
            >
                {typeof property.icon === 'string' ? (
                    <Box
                        component={Icon}
                        sx={[{ color: 'custom.gray.super' }, getSquare(20)]}
                        name={getPropertyIcon(property.icon as PropertyIcon)}
                    />
                ) : (
                    property.icon
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    flex={1}
                    justifyContent="space-between"
                >
                    <Typography
                        variant="body3"
                        maxWidth={COL_WIDTH}
                        noWrap
                        title={property.name}
                    >
                        {property.name}
                    </Typography>
                    {enabled &&
                        property.itemType === 'phone' &&
                        isMultipleContactPhonesEnabled && (
                            <Tooltip
                                title={
                                    property.isPrimary
                                        ? 'Unmark as primary'
                                        : 'Mark as primary'
                                }
                                placement="top"
                            >
                                <IconButton
                                    onClick={() => {
                                        setPrimaryPhone({
                                            id: property.id,
                                            isPrimary: !property.isPrimary,
                                        });
                                    }}
                                    size="small"
                                    color="primary"
                                    aria-label={`${property.isPrimary ? 'Primary' : 'Not primary'} number button`}
                                >
                                    {property.isPrimary ? (
                                        <StarFilledIcon />
                                    ) : (
                                        <StarIcon />
                                    )}
                                </IconButton>
                            </Tooltip>
                        )}
                </Stack>
            </Stack>

            <Box flex={`0 0 ${COL_WIDTH}px`}>
                <EnhancedSelect
                    fullWidth
                    searchable
                    value={valueColumn?.id ?? ''}
                    options={columns.map((col, index) => ({
                        id: col.id ?? getColId(col.columnName, index),
                        label: col.columnName,
                    }))}
                    onClear={() => {
                        updateColumns(
                            columns.map((col) => {
                                if (col.id === valueColumn?.id) {
                                    const {
                                        itemType: _,
                                        field: __,
                                        propertyType: ___,
                                        propertyName: ____,
                                        ...nextCol
                                    } = col;

                                    return {
                                        ...nextCol,
                                        usage: 'skip',
                                    };
                                }

                                return col;
                            }),
                        );
                    }}
                    onChange={(value) => {
                        updateColumns(
                            columns.map((col) => {
                                if (col.id === value) {
                                    switch (property.itemType ?? 'property') {
                                        case 'field':
                                            return {
                                                ...col,
                                                itemType: property.itemType,
                                                field: property.id,
                                                propertyName: property.name,
                                                usage: 'existing-property',
                                            };
                                        case 'phone':
                                            return {
                                                ...col,
                                                itemType: property.itemType,
                                                field: property.id,
                                                isPrimary: property.isPrimary,
                                                propertyName: property.name,
                                                usage: 'existing-property',
                                            };
                                        case 'property':
                                            return {
                                                ...col,
                                                itemType: 'property',
                                                propertyName: property.name,
                                                propertyType: property.type,
                                                usage: 'existing-property',
                                            };
                                    }
                                }

                                return col;
                            }),
                        );
                    }}
                    disabled={!enabled}
                    size="small"
                />
            </Box>

            {valuePreview ? (
                <Box
                    flex={`0 0 ${COL_WIDTH}px`}
                    sx={{
                        px: 3,
                        py: 1.5,
                        borderRadius: 1.5,
                        border: '1px solid',
                        borderColor: 'custom.gray.light400',
                        background: 'rgba(236, 239, 254, 0.24)',
                    }}
                >
                    {typeof formattedValue === 'string' ? (
                        <Typography
                            variant="body3"
                            color="primary.dark"
                            display="block"
                            maxWidth={COL_WIDTH - 24 /* paddingX */}
                            noWrap
                            title={formattedValue}
                        >
                            {formattedValue}
                        </Typography>
                    ) : (
                        formattedValue
                    )}
                </Box>
            ) : (
                <Box
                    flex={1}
                    sx={{
                        height: 8,
                        background:
                            'linear-gradient(90deg, #EAEDF2 0%, #FFF 100%)',
                        borderRadius: 1.6,
                    }}
                />
            )}
        </Stack>
    );
};
