import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const MessageIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0033 3.125C6.2063 3.125 3.12827 6.20304 3.12827 10C3.12827 11.099 3.38565 12.136 3.84286 13.0558C4.05797 13.4885 4.05286 14.0358 4.002 14.4951C3.94788 14.9837 3.82405 15.5093 3.68955 15.9808C3.59713 16.3047 3.49719 16.6116 3.4058 16.875H10.0033C13.8002 16.875 16.8783 13.797 16.8783 10C16.8783 6.20304 13.8002 3.125 10.0033 3.125ZM1.9269 17.2597L1.92735 17.2586L1.92961 17.2532L1.93932 17.2294C1.94799 17.2081 1.96094 17.1759 1.97741 17.1342C2.01036 17.0508 2.05731 16.9295 2.11224 16.7803C2.22245 16.4809 2.36316 16.0737 2.48751 15.6378C2.61284 15.1985 2.71635 14.748 2.7596 14.3575C2.80611 13.9375 2.76841 13.7025 2.72352 13.6122C2.1823 12.5234 1.87827 11.2963 1.87827 10C1.87827 5.51269 5.51595 1.875 10.0033 1.875C14.4906 1.875 18.1283 5.51269 18.1283 10C18.1283 14.4873 14.4906 18.125 10.0033 18.125H2.50393C2.29498 18.125 2.09979 18.0206 1.98387 17.8467C1.86797 17.6729 1.84663 17.4526 1.9269 17.2597C1.92691 17.2597 1.9269 17.2597 1.9269 17.2597ZM6.04168 10C6.04168 9.65482 6.3215 9.375 6.66668 9.375H6.67501C7.02019 9.375 7.30001 9.65482 7.30001 10C7.30001 10.3452 7.02019 10.625 6.67501 10.625H6.66668C6.3215 10.625 6.04168 10.3452 6.04168 10ZM9.37501 10C9.37501 9.65482 9.65483 9.375 10 9.375H10.0083C10.3535 9.375 10.6333 9.65482 10.6333 10C10.6333 10.3452 10.3535 10.625 10.0083 10.625H10C9.65483 10.625 9.37501 10.3452 9.37501 10ZM12.7083 10C12.7083 9.65482 12.9882 9.375 13.3333 9.375H13.3417C13.6869 9.375 13.9667 9.65482 13.9667 10C13.9667 10.3452 13.6869 10.625 13.3417 10.625H13.3333C12.9882 10.625 12.7083 10.3452 12.7083 10Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
