import {
    Box,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { Actions } from './Actions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExternalUpdateBehavior } from '../../../../api/types';
import * as Yup from 'yup';

type Props = {
    propertyName: string;
    onClose: () => void;
    isPending?: boolean;
    onUpdateBehaviorChanged: (
        externalUpdateBehavior: ExternalUpdateBehavior,
    ) => void;
    externalUpdateBehavior?: ExternalUpdateBehavior;
};

const schema = Yup.object({
    externalUpdateBehavior: Yup.string<ExternalUpdateBehavior>()
        .oneOf(['never', 'always', 'if_empty'])
        .required(),
});

export const ExternalPropertyConfigurator = ({
    propertyName,
    onClose,
    onUpdateBehaviorChanged,
    externalUpdateBehavior = 'never',
    isPending = false,
}: Props) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            externalUpdateBehavior,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit(({ externalUpdateBehavior }) => {
        onUpdateBehaviorChanged(externalUpdateBehavior);
    });
    return (
        <Box py={1}>
            <Box py={2} px={3}>
                <TextField
                    fullWidth
                    value={propertyName}
                    size="small"
                    disabled
                />
            </Box>
            <Divider sx={{ mt: 1, mb: 0.75 }} />
            <form onSubmit={onSubmit}>
                <Box p={2} pl={1}>
                    <RadioGroup>
                        <Controller
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FormControlLabel
                                        {...field}
                                        sx={{ margin: 0 }}
                                        checked={field.value === 'never'}
                                        onChange={(_, checked) => {
                                            if (checked) {
                                                field.onChange('never');
                                            }
                                        }}
                                        control={
                                            <Radio size="small" color="info" />
                                        }
                                        label="Never overwrite"
                                    />
                                );
                            }}
                            name="externalUpdateBehavior"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    sx={{ margin: 0 }}
                                    checked={field.value === 'if_empty'}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            field.onChange('if_empty');
                                        }
                                    }}
                                    control={
                                        <Radio size="small" color="info" />
                                    }
                                    label="Complete if missing"
                                />
                            )}
                            name="externalUpdateBehavior"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    {...field}
                                    sx={{ margin: 0 }}
                                    checked={field.value === 'always'}
                                    onChange={(_, checked) => {
                                        if (checked) {
                                            field.onChange('always');
                                        }
                                    }}
                                    control={
                                        <Radio size="small" color="info" />
                                    }
                                    label="Overwrite field"
                                />
                            )}
                            name="externalUpdateBehavior"
                        />
                    </RadioGroup>
                </Box>
                <Divider sx={{ mb: 1 }} />
                <Actions onRemove={onClose} isPending={isPending} />
            </form>
        </Box>
    );
};
