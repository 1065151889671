import { Box, Divider, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { parsePhoneNumber } from '../../../../utils/phoneNumber';
import { useMeQueryData } from '../../../../queries/user';
import { useMemo } from 'react';
import { Contact } from '../../../../api/types';
import { useContactCreateQuery } from '../../../../queries/contacts';
import { Actions } from './Actions';
import { useContactPhoneCreate } from '../../../../queries/contact-phones';
import { isAxiosError } from 'axios';

type FormFields = {
    label: string;
    phone: string;
};

export function PhoneConfigurator({
    onClose,
    contact,
}: {
    onClose: () => void;
    contact: Partial<Contact>;
}) {
    const me = useMeQueryData();
    const { mutateAsync: createContact } = useContactCreateQuery();
    const { mutateAsync: createContactPhone } = useContactPhoneCreate();
    const schema = useMemo(
        () =>
            Yup.object({
                label: Yup.string().required('Property name is required'),
                phone: Yup.string()
                    .required('Phone number is required')
                    .test(
                        'isValidPhone',
                        'Invalid phone number',
                        (value: string) => {
                            if (!value) {
                                return false;
                            }

                            return !!parsePhoneNumber(
                                value,
                                me?.activeTeam.countryCode,
                            );
                        },
                    ),
            }),
        [me],
    );

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async ({ phone, label }: FormFields) => {
        let currentContact = contact;

        if (!contact?.id) {
            currentContact = await createContact(contact);
        }

        try {
            await createContactPhone({
                id: currentContact?.id ?? '',
                phone,
                label,
                isPrimary: currentContact.phones?.length === 0,
            });
        } catch (error) {
            if (isAxiosError(error) && error.response?.data.message) {
                setError('phone', {
                    message: error.response?.data.message,
                });
                return;
            }
        }

        onClose();
    };

    return (
        <Box py={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box px={3} py={2}>
                    <Stack spacing={2}>
                        <TextField
                            {...register('label')}
                            fullWidth
                            autoFocus
                            size="small"
                            placeholder="Property name"
                            error={!!errors.label}
                            helperText={
                                errors.label ? errors.label.message : null
                            }
                        />
                        <TextField
                            {...register('phone')}
                            fullWidth
                            size="small"
                            placeholder="Phone number"
                            error={!!errors.phone}
                            helperText={
                                errors.phone ? errors.phone.message : null
                            }
                        />
                    </Stack>
                </Box>
                <Divider sx={{ mt: 1, mb: 0.75 }} />
                <Actions onRemove={onClose} />
            </form>
        </Box>
    );
}
