import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react';
import noop from 'lodash/noop';
import Papa from 'papaparse';
import {
    CohortMeta,
    Column,
    ContactPropertyType,
    Feature,
} from '../../../api/types';
import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { CSV_PARSER_OPTIONS } from './utils';
import { useDefaultFields } from './use-default-fields';
import { ExtendedUserPropertyModel } from './PropertyRow';
import { PropertyIcon } from '../../../icons/iconsMap';
import { PropertySource } from '../../../components/ContactProperty/types';

const defaultPhoneNumber: ExtendedUserPropertyModel = {
    id: 'phone',
    icon: 'phone',
    type: ContactPropertyType.Phone,
    name: 'Phone number',
    value: '',
    source: PropertySource.Clerk,
    itemType: 'phone',
};

type CsvImportContextState = {
    columns: Column[];
    cohort?: CohortMeta;
    newCohortName?: string;
    file: File | null;
    preview: Record<string, string>;
    numOfLines: number;
    isPrivate: boolean;
    needPreview: boolean;
    error?: React.ReactNode;
    newPhoneNumberFields: ExtendedUserPropertyModel[];
};

export function getColId(columnName: string, index: number) {
    return `${columnName.toLowerCase().replaceAll(/s+/g, '_')}-${index}`;
}

const defaultState: CsvImportContextState = {
    columns: [],
    file: null,
    preview: {},
    numOfLines: 0,
    isPrivate: false,
    error: null,
    newPhoneNumberFields: [],
    needPreview: true,
};

export const CsvImportContext = createContext<{
    state: CsvImportContextState;
    dispatch: React.Dispatch<CsvImportAction>;
}>({
    state: defaultState,
    dispatch: noop,
});

export type CsvImportAction =
    | {
          type: 'UPDATE_COLUMNS';
          payload: Column[];
      }
    | {
          type: 'SET_COHORT_NAME';
          payload: string;
      }
    | {
          type: 'SET_COHORT';
          payload: CohortMeta;
      }
    | {
          type: 'CLEAR_COHORT';
      }
    | {
          type: 'CLEAR_FORM';
          payload?: boolean;
      }
    | {
          type: 'SET_FILE';
          payload: File;
      }
    | {
          type: 'SET_PREVIEW';
          payload: Record<string, string>;
      }
    | {
          type: 'SET_NUMBER_OF_ROWS';
          payload: number;
      }
    | {
          type: 'TOGGLE_IS_PRIVATE';
      }
    | {
          type: 'SET_ERROR';
          payload: React.ReactNode;
      }
    | {
          type: 'SET_IS_PRIVATE';
          payload: boolean;
      }
    | {
          type: 'ADD_PHONE_NUMBER_FIELD';
          payload: {
              name: string;
              icon: PropertyIcon;
          };
      }
    | {
          type: 'SET_PRIMARY_PHONE';
          payload: {
              id: string;
              isPrimary: boolean;
          };
      };

type ReducerFn = (
    state: CsvImportContextState,
    action: CsvImportAction,
) => CsvImportContextState;

const reducer: ReducerFn = (
    state: CsvImportContextState,
    action: CsvImportAction,
) => {
    switch (action.type) {
        case 'UPDATE_COLUMNS':
            return {
                ...state,
                columns: action.payload,
            };
        case 'SET_COHORT_NAME':
            return {
                ...state,
                newCohortName: action.payload,
            };
        case 'SET_COHORT':
            return {
                ...state,
                cohort: action.payload,
            };
        case 'CLEAR_COHORT':
            return {
                ...state,
                isPrivate: defaultState.isPrivate,
                cohort: undefined,
                newCohortName: undefined,
            };
        case 'CLEAR_FORM': {
            const clearFile = !action.payload;

            return {
                ...defaultState,
                needPreview: clearFile,
                newPhoneNumberFields: state.newPhoneNumberFields,
                preview: clearFile ? {} : state.preview,
                numOfLines: clearFile ? 0 : state.numOfLines,
                file: clearFile ? null : state.file,
                columns: clearFile
                    ? []
                    : state.columns.map(
                          ({
                              propertyType: _,
                              propertyName: __,
                              field: ___,
                              itemType: ____,
                              ...col
                          }) => ({
                              ...col,
                              usage: 'skip',
                          }),
                      ),
            };
        }
        case 'SET_FILE':
            return {
                ...state,
                file: action.payload,
            };
        case 'SET_PREVIEW':
            return {
                ...state,
                preview: action.payload,
            };
        case 'SET_NUMBER_OF_ROWS':
            return {
                ...state,
                numOfLines: action.payload,
                needPreview: false,
            };
        case 'TOGGLE_IS_PRIVATE':
            return {
                ...state,
                isPrivate: !state.isPrivate,
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload,
            };
        case 'SET_IS_PRIVATE':
            return {
                ...state,
                isPrivate: action.payload,
            };
        case 'ADD_PHONE_NUMBER_FIELD':
            return {
                // for multiple phone numbers
                ...state,
                newPhoneNumberFields: [
                    ...state.newPhoneNumberFields,
                    {
                        id: `phone-${state.newPhoneNumberFields.length + 1}`,
                        icon: 'phone',
                        type: ContactPropertyType.Phone,
                        name: action.payload.name,
                        value: '',
                        source: PropertySource.Clerk,
                        itemType: 'phone',
                        isPrimary: state.newPhoneNumberFields.length === 0,
                    },
                ],
            };
        case 'SET_PRIMARY_PHONE': {
            return {
                ...state,
                newPhoneNumberFields: state.newPhoneNumberFields.map((field) =>
                    field.id === action.payload.id
                        ? { ...field, isPrimary: action.payload.isPrimary }
                        : { ...field, isPrimary: false },
                ),
            };
        }
        default:
            return state;
    }
};

export const CsvImportProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const workerStarted = useRef(false);
    const me = useMeQueryData();
    const defaultFields = useDefaultFields();

    const [state, dispatch] = useReducer(reducer, {
        ...defaultState,
        newPhoneNumberFields: isMultipleContactPhonesEnabled
            ? [defaultPhoneNumber]
            : [],
        isPrivate: !(me?.activeTeam.contactsPublicByDefault ?? true),
    });

    const availableFields = useMemo(
        () =>
            isMultipleContactPhonesEnabled
                ? [...defaultFields, defaultPhoneNumber]
                : defaultFields,
        [defaultFields, isMultipleContactPhonesEnabled],
    );

    useEffect(() => {
        if (!state.file) {
            return;
        }

        Papa.parse(state.file, {
            ...CSV_PARSER_OPTIONS,
            complete: (results) => {
                if (results?.errors?.length) {
                    dispatch({
                        type: 'SET_ERROR',
                        payload: results.errors[0].message,
                    });
                    return;
                }

                if (results?.meta.fields) {
                    const columns = results.meta.fields.map<Column>(
                        (columnName, index) => ({
                            columnName,
                            id: getColId(columnName, index),
                            usage: 'skip',
                        }),
                    );

                    dispatch({
                        type: 'UPDATE_COLUMNS',
                        payload: columns.map((col) => {
                            const lowerCaseName = col.columnName.toLowerCase();
                            const field = availableFields.find(
                                ({ id }) => id === lowerCaseName,
                            );

                            if (field) {
                                return {
                                    ...col,
                                    usage: 'existing-property',
                                    itemType: field?.itemType,
                                    field: field?.id,
                                    propertyName: field?.name,
                                };
                            }

                            return col;
                        }),
                    });
                }
            },
        });
    }, [availableFields, state.file]);

    useEffect(() => {
        if (
            !state.columns.length ||
            !state.file ||
            workerStarted.current ||
            !state.needPreview
        ) {
            return;
        }

        workerStarted.current = true;

        let numOfLines = 0;
        let nextPreview: Record<string, string> = {};
        Papa.parse(state.file, {
            // read entire file in worker to get number of lines and previews
            worker: true,
            header: true,
            step: (results) => {
                const row = results.data as Record<string, string>;

                if (
                    state.columns.length !== Object.keys(nextPreview).length &&
                    row
                ) {
                    nextPreview = Object.keys(row).reduce(
                        (acc, columnName) => {
                            const index = state.columns.findIndex(
                                (col) => col.columnName === columnName,
                            );
                            const colId = getColId(columnName, index);

                            if (
                                index !== -1 &&
                                row[columnName] &&
                                !nextPreview[colId]
                            ) {
                                return {
                                    ...acc,
                                    [colId]: row[columnName],
                                };
                            }
                            return acc;
                        },
                        { ...nextPreview },
                    );
                }
                numOfLines++;
            },
            complete: () => {
                dispatch({
                    type: 'SET_NUMBER_OF_ROWS',
                    payload: numOfLines,
                });

                if (Object.keys(nextPreview).length) {
                    dispatch({
                        type: 'SET_PREVIEW',
                        payload: nextPreview,
                    });
                }
                workerStarted.current = false;
            },
        });
    }, [state.file, state.columns, state.needPreview]);

    return (
        <CsvImportContext.Provider value={{ state, dispatch }}>
            {children}
        </CsvImportContext.Provider>
    );
};

export const useCsvImportContext = () => {
    const { state, dispatch } = useContext(CsvImportContext);

    return {
        enabled: !!state.numOfLines && !!state.columns.length,
        columns: state.columns,
        cohort: state.cohort,
        newCohortName: state.newCohortName,
        preview: state.preview,
        file: state.file,
        numOfLines: state.numOfLines,
        isPrivate: state.isPrivate,
        error: state.error,
        newPhoneNumberFields: state.newPhoneNumberFields,
        setCohortName: (payload: string) => {
            dispatch({ type: 'SET_COHORT_NAME', payload });
        },
        setCohort: (payload: CohortMeta) => {
            dispatch({ type: 'SET_COHORT', payload });
        },
        clearCohort: () => {
            dispatch({ type: 'CLEAR_COHORT' });
        },
        clearForm: (withFile = false) => {
            dispatch({ type: 'CLEAR_FORM', payload: withFile });
        },
        updateColumns: (payload: Column[]) => {
            dispatch({ type: 'UPDATE_COLUMNS', payload });
        },
        setFile: (payload: File) => {
            dispatch({ type: 'SET_FILE', payload });
        },
        toggleIsPrivate: () => {
            dispatch({ type: 'TOGGLE_IS_PRIVATE' });
        },
        setIsPrivate: (isPrivate: boolean) => {
            dispatch({ type: 'SET_IS_PRIVATE', payload: isPrivate });
        },
        setError: (payload: React.ReactNode) => {
            dispatch({ type: 'SET_ERROR', payload });
        },
        addPhoneNumberField: (payload: {
            name: string;
            icon: PropertyIcon;
        }) => {
            dispatch({ type: 'ADD_PHONE_NUMBER_FIELD', payload });
        },
        setPrimaryPhone: (payload: { id: string; isPrimary: boolean }) => {
            dispatch({ type: 'SET_PRIMARY_PHONE', payload });
        },
    };
};
