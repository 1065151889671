import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const UploadColoredIcon = (
    props: Exclude<SvgIconProps, 'component'>,
) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
            <path
                d="M34.2107 13.3335H5.79067C3.66852 13.3335 2.08667 15.2902 2.53132 17.3653L5.53814 31.397C6.1968 34.4708 8.91322 36.6668 12.0568 36.6668H27.9447C31.0882 36.6668 33.8047 34.4708 34.4633 31.397L37.4702 17.3653C37.9147 15.2902 36.3328 13.3335 34.2107 13.3335Z"
                fill="#657DF6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.75 23.0472C18.6722 23.1056 18.5977 23.1702 18.5268 23.2411L15.8839 25.8841C15.3957 26.3722 14.6043 26.3722 14.1161 25.8841C13.628 25.3959 13.628 24.6044 14.1161 24.1162L16.7592 21.4732C18.549 19.6834 21.451 19.6834 23.2408 21.4732L25.8838 24.1162C26.372 24.6044 26.372 25.3959 25.8838 25.8841C25.3957 26.3722 24.6043 26.3722 24.1162 25.8841L21.4732 23.2411C21.4023 23.1702 21.3278 23.1056 21.25 23.0472V30.0001C21.25 30.6906 20.6903 31.2501 20 31.2501C19.3097 31.2501 18.75 30.6906 18.75 30.0001V23.0472Z"
                fill="white"
            />
            <path
                d="M33.3334 13.3335V11.6668C33.3334 8.90541 31.0949 6.66683 28.3334 6.66683H23.6625C22.549 6.66683 21.4675 6.2952 20.5892 5.61083L19.0219 4.3895C18.1437 3.70513 17.062 3.3335 15.9486 3.3335H11.6667C8.90527 3.3335 6.66669 5.57208 6.66669 8.3335V13.3335H33.3334Z"
                fill="#324AC3"
            />
        </svg>
    </SvgIcon>
);
