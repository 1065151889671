import { useCallback } from 'react';
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from '@hello-pangea/dnd';
import { Inbox } from '../../../api/types';
import CollapsibleSection from '../../../components/CollapsibleSection';
import { list } from '../SideBarInboxes/styles';
import { Box } from '@mui/material';
import { InboxDraggableItem } from '../../../elements/InboxItem/InboxDraggableItem';
import useSaveInboxesOrder from '../../../hooks/useSaveInboxesOrder';
import useSortedInboxes from '../../../hooks/useSortedInboxes';

interface SideBarMutedInboxesProps {
    setMenuOpen: (value: boolean) => void;
}

export const SideBarMutedInboxes = ({
    setMenuOpen,
}: SideBarMutedInboxesProps) => {
    const { muted: mutedSortedInboxes } = useSortedInboxes();
    const saveInboxesOrder = useSaveInboxesOrder(true);

    const handleOnDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            saveInboxesOrder(result.source.index, result.destination.index);
        },
        [saveInboxesOrder],
    );

    if (mutedSortedInboxes.length > 0) {
        return (
            <CollapsibleSection
                title={`Muted Phones (${mutedSortedInboxes.length})`}
            >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="inboxes-list">
                        {(providedDroppable: DroppableProvided) => (
                            <Box
                                component="ul"
                                sx={list}
                                ref={providedDroppable.innerRef}
                                {...providedDroppable}
                            >
                                {mutedSortedInboxes.map(
                                    (inbox: Inbox, i: number) => (
                                        <InboxDraggableItem
                                            index={i}
                                            key={inbox.id}
                                            inbox={inbox}
                                            onClick={() => setMenuOpen(false)}
                                            isMuted
                                        />
                                    ),
                                )}
                                {providedDroppable.placeholder}
                            </Box>
                        )}
                    </Droppable>
                </DragDropContext>
            </CollapsibleSection>
        );
    }

    return null;
};
