import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const SelectIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3.125C6.20304 3.125 3.125 6.20304 3.125 10C3.125 13.797 6.20304 16.875 10 16.875C13.797 16.875 16.875 13.797 16.875 10C16.875 6.20304 13.797 3.125 10 3.125ZM1.875 10C1.875 5.51269 5.51269 1.875 10 1.875C14.4873 1.875 18.125 5.51269 18.125 10C18.125 14.4873 14.4873 18.125 10 18.125C5.51269 18.125 1.875 14.4873 1.875 10ZM13.7573 7.04075C14.0109 7.27488 14.0267 7.67029 13.7926 7.92392L9.1772 12.9239C9.05888 13.0521 8.89238 13.125 8.71795 13.125C8.54351 13.125 8.37701 13.0521 8.2587 12.9239L6.20741 10.7017C5.97329 10.4481 5.9891 10.0527 6.24274 9.81853C6.49638 9.5844 6.89179 9.60021 7.12592 9.85385L8.71795 11.5786L12.8741 7.07607C13.1082 6.82244 13.5036 6.80662 13.7573 7.04075Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
