import { indicatorsHidden } from './styles';
import { Stack, Tooltip } from '@mui/material';
import { Inbox, InboxStatus } from '../../api/types';
import { Indicator } from '../../components/common/Indicator';
import { useUnreadMessagesCountForInbox } from '../../queries/messages';
import { getStatusIcon, getStatusText } from './utils';

type Props = {
    isHidden: boolean;
    inbox: Inbox;
};

export function Indicators({ isHidden = false, inbox }: Props) {
    const { status, id } = inbox;
    const unreadCount = useUnreadMessagesCountForInbox(id).data || 0;
    const statusIcon = getStatusIcon(status);

    return (
        <Stack
            pr={2}
            spacing={1}
            alignItems="end"
            className="indicators"
            sx={isHidden ? indicatorsHidden : undefined}
            minWidth={40}
        >
            <Stack direction="row" spacing={1} alignItems="center">
                {status !== InboxStatus.ACTIVE && statusIcon && (
                    <Tooltip
                        title={getStatusText(status, inbox)}
                        placement="top"
                    >
                        {statusIcon}
                    </Tooltip>
                )}
            </Stack>
            {unreadCount > 0 && (
                <Indicator data-testid="inbox-unread-count">
                    {unreadCount < 9 ? unreadCount : '9+'}
                </Indicator>
            )}
        </Stack>
    );
}
