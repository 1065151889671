import { Link, Stack } from '@mui/material';
import { Add } from '../../../components/ConversationsList/CAdd';
import { getSquare } from '../../../theme/style.helpers';
import { NewPropertyPopover } from './NewPropertyPopover';
import { useMenu } from '../../../hooks/use-menu';

export const CreatePropertyMenu = () => {
    const menu = useMenu();
    return (
        <>
            <Link
                sx={{
                    alignSelf: 'flex-start',
                }}
                onClick={menu.handleOpen}
            >
                <Stack alignItems="center" direction="row" spacing={4}>
                    <Add sx={getSquare(20)} />
                    <span>Add a new property</span>
                </Stack>
            </Link>

            <NewPropertyPopover menu={menu} />
        </>
    );
};
