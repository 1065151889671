import { BaseRange, Editor, Range } from 'slate';
import { useCallback, useState } from 'react';
import { CustomEditor } from '../../../slate';
import { findEmoji, insertEmoji } from './utils';
import { useEmojiShortcodes } from './hooks/use-emoji-shortcodes';

export const useEmojiAutocomplete = (editor: CustomEditor) => {
    const shortcodes = useEmojiShortcodes();
    const [targetRange, setTargetRange] = useState<BaseRange>();
    const [searchString, setSearchString] = useState('');
    const [searchIndex, setSearchIndex] = useState(0);
    const emojis = findEmoji(searchString, shortcodes).slice(0, 10);

    const handleInsertEmoji = useCallback(
        (emoji: string) => {
            if (!targetRange) {
                return;
            }

            insertEmoji(editor, targetRange, shortcodes[emoji]);
            setTargetRange(undefined);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editor, targetRange],
    );

    const onKeyDownHandler = useCallback(
        (event: React.KeyboardEvent) => {
            if (!targetRange) {
                return;
            }

            switch (event.key) {
                case 'Escape':
                    event.preventDefault();
                    setTargetRange(undefined);
                    setSearchString('');
                    setSearchIndex(0);
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    setSearchIndex(searchIndex + 1);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    setSearchIndex(searchIndex - 1);
                    break;
                case 'Enter':
                case 'Tab':
                case ':':
                    if (emojis[searchIndex]) {
                        event.preventDefault();
                        handleInsertEmoji(emojis[searchIndex]);
                    }
                    break;
            }
        },
        [emojis, handleInsertEmoji, searchIndex, targetRange],
    );

    const onChangeHandler = useCallback(() => {
        try {
            const { selection } = editor;
            if (selection && Range.isCollapsed(selection)) {
                const [start] = Range.edges(selection);
                const wordBefore = Editor.before(editor, start, {
                    unit: 'word',
                });
                const before = wordBefore && Editor.before(editor, wordBefore);
                const beforeRange =
                    before && Editor.range(editor, before, start);
                const beforeText =
                    beforeRange && Editor.string(editor, beforeRange);

                const beforeColonMatch =
                    beforeText && beforeText.match(/^:(\w+)$/);

                const after = Editor.after(editor, start);
                const afterRange = Editor.range(editor, start, after);
                const afterText = Editor.string(editor, afterRange);
                const afterMatch = afterText.match(/^(\s|$)/);

                if (beforeColonMatch && afterMatch) {
                    setTargetRange(beforeRange);
                    setSearchString(beforeColonMatch[1]);
                    setSearchIndex(0);
                }
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Slate error:', e);
        }
    }, [editor]);

    return {
        emojis,
        searchIndex,
        searchString,
        targetRange,
        handleInsertEmoji,
        onKeyDown: onKeyDownHandler,
        onChange: onChangeHandler,
    };
};
