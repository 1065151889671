import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { removeCampaign, renameCampaign } from '../api/campaigns';
import client from '../api/http';
import {
    Campaign,
    CampaignMeta,
    CampaignOrMessageCreateParams,
    CreateCampaignDto,
} from '../api/campaign.types';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { validate } from 'uuid';
import { UUID } from '../types/uuid';
import { useTrack } from '../contexts/analytics';
import { useLocation, useNavigate } from 'react-router';

const CAMPAIGNS_LIST_KEY = 'campaigns_list';
const SCHEDULED_CAMPAIGNS_LIST_KEY = 'scheduled_campaigns_list';
const CAMPAIGN_KEY = 'campaign';
export const CAMPAIGN_PAGES = 'campaign_pages';

export type CampaignPage = {
    data: Campaign[];
    page: number;
    total: number;
    limit: number;
};

export const useCampaign = (campaignId?: number) =>
    useQuery({
        queryKey: [CAMPAIGN_KEY, campaignId],
        queryFn: ({ queryKey: [_, id] }) =>
            client()
                .get<Campaign>(`/campaigns/${id}`)
                .then(({ data }) => data),
        enabled: !!campaignId,
    });

/** @depreacted: use useCampaign instead */
export function useCampaignQuery(campaignId: number) {
    const { data, isPending: isPending } = useCampaign(campaignId);

    return { campaign: data, isPending };
}

export const mapLegacy = (
    data: CreateCampaignDto & { attachments?: UUID[] | undefined },
): CampaignOrMessageCreateParams => ({
    inboxId: data.inboxId,
    attachments: data.attachments,
    contacts: data.contactIds.filter(validate),
    phones: data.contactIds.filter((idOrPhone) =>
        isValidPhoneNumber(idOrPhone),
    ),
    body: data.message,
    timestamp: data.sendAt,
    templateId: data.templateId,
    name: data.name,
});
const CAMPAIGN_PAGE_LIMIT = 15;
export const useCampaignPage = ({
    inboxId,
    inboxIds,
}: {
    inboxId?: UUID;
    inboxIds?: UUID[];
}) => {
    const inboxes = [...(inboxIds ?? []), inboxId].filter(Boolean);
    return useInfiniteQuery<CampaignPage>({
        queryFn: ({ pageParam }) =>
            client()
                .get<CampaignPage>('/v2/campaigns', {
                    params: {
                        inboxId,
                        inboxIds,
                        limit: CAMPAIGN_PAGE_LIMIT,
                        page: pageParam,
                    },
                })
                .then(({ data }) => data),
        initialPageParam: 1,
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [CAMPAIGN_PAGES, inboxes],
        getNextPageParam: (last) => {
            return (last?.data && last.data.length) < CAMPAIGN_PAGE_LIMIT
                ? null
                : (last?.page || 0) + 1;
        },
    });
};

export const useCampaignCreate = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['campaign_create_v3'],
        mutationFn: (data: CampaignOrMessageCreateParams) =>
            client()
                .post<Campaign>('/v3/campaigns', data)
                .then(({ data }) => data),
        onSuccess: ({ inboxId, body, type, contactIds }: Campaign) => {
            track('campaign_created', {
                bodyLength: body?.length || 0,
                contactsCount: contactIds.length,
                type,
            });

            queryClient.invalidateQueries({
                queryKey: [CAMPAIGN_PAGES, inboxId],
            });
        },
    });
};

export function useCampaignRenameQuery(campaignId: number) {
    const track = useTrack();

    return useMutation<
        Campaign,
        unknown,
        string,
        { prevCampaigns: CampaignMeta[] }
    >({
        mutationKey: ['campaign_rename'],
        mutationFn: (name) => renameCampaign(campaignId, name),
        onSuccess: (renamed) => {
            track('campaign_renamed');

            queryClient.setQueryData<Campaign>(
                [CAMPAIGN_KEY, campaignId],
                (prev) => {
                    if (!prev) {
                        return prev;
                    }
                    return { ...prev, name: renamed.name };
                },
            );

            if (renamed.inboxId) {
                queryClient.setQueryData<{ pages: CampaignPage[] }>(
                    [CAMPAIGN_PAGES, renamed.inboxId],
                    (prev) => {
                        if (!prev) {
                            return prev;
                        }
                        return {
                            ...prev,
                            pages: prev.pages.map((page) => ({
                                ...page,
                                data: page.data.map((campaign) => {
                                    return campaign.id === renamed.id
                                        ? {
                                              ...campaign,
                                              name: renamed.name,
                                          }
                                        : campaign;
                                }),
                            })),
                        };
                    },
                );
            }
        },
        onError: (_error, _name, context) => {
            const { prevCampaigns } = context || { prevCampaigns: [] };

            queryClient.setQueryData<CampaignMeta[]>(
                [CAMPAIGNS_LIST_KEY],
                () => prevCampaigns,
            );
        },
    });
}

export function useCampaignRemoveQuery(campaignId: number) {
    const track = useTrack();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useMutation<void, unknown, void, { prevCampaigns: CampaignMeta[] }>({
        mutationKey: ['campaign_delete'],
        mutationFn: () => removeCampaign(campaignId),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: [CAMPAIGNS_LIST_KEY] });

            const prevCampaigns =
                queryClient.getQueryData<CampaignMeta[]>([
                    CAMPAIGNS_LIST_KEY,
                ]) || [];

            queryClient.setQueryData<CampaignMeta[]>(
                [CAMPAIGNS_LIST_KEY],
                (campaigns = []) =>
                    campaigns.filter(({ id }) => id !== campaignId),
            );

            return { prevCampaigns };
        },
        onError: (_error, _, context) => {
            track('campaign_delete_error');
            const { prevCampaigns } = context || { prevCampaigns: [] };

            queryClient.setQueryData<CampaignMeta[]>(
                [CAMPAIGNS_LIST_KEY],
                () => prevCampaigns,
            );
        },
        onSuccess: () => {
            track('campaign_deleted');

            // Check if certain campaign view is on screen
            const regex = new RegExp(`/campaigns/${campaignId}`);
            if (regex.test(pathname)) {
                navigate('/contacts/book');
            }

            queryClient.removeQueries({ queryKey: [CAMPAIGN_KEY, campaignId] });
            queryClient.invalidateQueries({ queryKey: [CAMPAIGNS_LIST_KEY] });
            queryClient.invalidateQueries({
                queryKey: [SCHEDULED_CAMPAIGNS_LIST_KEY],
            });
        },
    });
}
