import React from 'react';
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material';
import * as styles from './styles';
import { Navigation } from './Navigation/Navigation';
import { ContentHeader } from './ContentHeader';
import { SidebarDrawer } from '../SideBar/SidebarDrawer';
import { FullArrowLeftIcon } from './FullArrowLeftIcon';
import { useSidebar } from '../../contexts/sidebar';
import { useNavigate } from 'react-router';
import { MenuIcon } from './MenuIcon';
import { CloseIcon } from '../../icons/common/CCloseIcon';
import { useIsBannerShown } from '../../main/settings/Compliance/useIsBannerShown';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { BannerHolder } from '../../main/settings/Compliance/BannerHolder/BannerHolder';

type Props = {
    children: React.ReactNode;
    title: string;
    category?: string;
    description?: React.ReactNode;
    action?: React.ReactNode | null;
    breadcrumbs?: React.ReactNode[];
};

export function SettingsLayout({
    children,
    title,
    description,
    action,
    category = 'Settings',
    breadcrumbs,
}: Props) {
    const { isCollapsed, toggleOpen, isOpened } = useSidebar();
    const navigate = useNavigate();
    const isBannerShown = useIsBannerShown();
    const isMobile = useIsDownMd();
    const isShrinkMode = isCollapsed && !isMobile;

    return (
        <>
            <BannerHolder />
            <Box
                sx={[
                    styles.layout,
                    {
                        maxHeight: isBannerShown
                            ? `calc(100vh - ${BANNER_HEIGHT}px)`
                            : '100vh',
                    },
                ]}
            >
                <Box sx={styles.layoutHeader}>
                    <IconButton
                        onClick={toggleOpen}
                        sx={styles.layoutHeaderMenuButton}
                        aria-label={
                            isOpened ? 'Close mobile menu' : 'Open mobile menu'
                        }
                    >
                        {isOpened ? <CloseIcon /> : <MenuIcon />}
                    </IconButton>
                </Box>
                <SidebarDrawer>
                    <Box flex={1}>
                        <Box sx={styles.header}>
                            {isShrinkMode ? (
                                <Tooltip
                                    disableFocusListener
                                    arrow
                                    title="Home"
                                >
                                    <IconButton
                                        sx={styles.shrinkHomeButton}
                                        onClick={() => navigate('/')}
                                    >
                                        <FullArrowLeftIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Button
                                    sx={styles.homeButton}
                                    variant="text"
                                    startIcon={<FullArrowLeftIcon />}
                                    onClick={() => navigate('/')}
                                >
                                    Home
                                </Button>
                            )}
                        </Box>
                        <Divider />
                        <Navigation />
                        <Divider sx={{ marginTop: 4 }} />
                    </Box>
                </SidebarDrawer>
                <Box sx={styles.container}>
                    <ContentHeader
                        title={title}
                        description={description}
                        category={category}
                        action={action}
                        breadcrumbs={breadcrumbs}
                    />
                    <Box sx={styles.layoutContainer} flex={1}>
                        {children}
                    </Box>
                </Box>
            </Box>
        </>
    );
}
