import { SxProps, Theme } from '@mui/material';

export const value = {
    flexGrow: 1,
    maxWidth: 1,
    paddingY: 1.5,
    cursor: 'pointer',
    padding: 2,
    borderRadius: 1.5,

    '&:hover': {
        backgroundColor: 'custom.action.hover',
    },
};

export const breakableValue: SxProps = {
    ...value,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
};

export const limitedValue: SxProps<Theme> = {
    ...value,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    height: 36,
};

export const readOnly: SxProps<Theme> = {
    ...value,
    cursor: 'default',

    '&:hover .MuiTypography-root': {
        color: 'inherit',
    },
};

export const emptyValue: SxProps<Theme> = {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 2,
    color: 'custom.gray.super',

    '&:hover .MuiTypography-root': {
        color: 'primary.dark',
    },
};
