import React from 'react';
import Loading from '../../../components/Loading/Loading';

const Lazy = React.lazy(() => import('./PaymentPlans'));

export const PaymentPlansPage = () => (
    <React.Suspense fallback={<Loading />}>
        <Lazy />
    </React.Suspense>
);
