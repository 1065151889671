import React, { createContext, useContext, ReactNode } from 'react';

interface InboxContextProps {
    inboxId: string;
}

const InboxContext = createContext<InboxContextProps | undefined>(undefined);

interface InboxProviderProps {
    inboxId: string;
    children: ReactNode;
}

export const InboxProvider: React.FC<InboxProviderProps> = ({
    inboxId,
    children,
}) => {
    return (
        <InboxContext.Provider value={{ inboxId }}>
            {children}
        </InboxContext.Provider>
    );
};

export const useInbox = (): InboxContextProps => {
    const context = useContext(InboxContext);
    if (!context || !context.inboxId) {
        throw new Error(
            'useInbox must be used within an InboxProvider with provided inbox id',
        );
    }
    return context;
};
