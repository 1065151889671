import { colors } from '../../../theme/palette';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Banner } from '../../../components/Banner/Banner';
import { useCurrentRole } from '../../../utils/user';
import { useMeQueryData } from '../../../queries/user';
import { RoleTypes } from '../../../api/types';

export function OutOfMessagesBanner() {
    const role = useCurrentRole();
    const me = useMeQueryData();
    const isWorkspaceOwner =
        (me?.activeRole?.type || RoleTypes.Member) === RoleTypes.WorkspaceOwner;

    if (!role) {
        return null;
    }

    return (
        <Banner color={colors.warning.background}>
            <Typography variant="body4" color="primary.dark" fontWeight={600}>
                You are out of messages.
            </Typography>
            <Typography
                sx={{ ml: 1, '& a:visited': { color: colors.info.main } }}
                variant="body4"
                color="primary.dark"
            >
                To continue sending messages, please upgrade your account.
                {isWorkspaceOwner && (
                    <NavLink
                        to={
                            me?.activeTeam.stripeSubscriptionId
                                ? '/settings/billing'
                                : '/payment/plan'
                        }
                    >
                        {' '}
                        Upgrade {' ->'}
                    </NavLink>
                )}
            </Typography>
        </Banner>
    );
}
