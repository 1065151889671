import {
    cleanByStatus,
    useDeleteConversation,
    useMarkConversationAsRead,
    useDownloadConversationLogs,
    useMarkConversationAsUnRead,
    useUpdateConversation,
} from '../../../queries/conversations';
import {
    ClerkPermission,
    Conversation,
    ConversationStatus,
    Feature,
} from '../../../api/types';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { useMenu } from '../../../hooks/use-menu';
import { ArchiveIcon } from '../../Inbox/CArchiveIcon';
import { VerticalThreeDotsIcon } from '../../../icons/common/VerticalThreeDotsIcon';
import { MuteIcon } from '../../Inbox/CMuteIcon';
import { DeleteIcon } from '../../../icons/common/CDeleteIcon';
import { useState } from 'react';
import { DialogAttentionIcon } from '../../../elements/Dialog/DialogAttentionIcon';
import { DialogTitleTwoLine } from '../../../elements/Dialog/DialogTitleTwoLine';
import { useNavigate } from 'react-router';
import { useTrack } from '../../../contexts/analytics';
import { MarkAsUnreadIcon } from '../../../icons/common/MarkAsUnreadIcon';
import { BlockPhoneListItem } from '../../../main/contacts/BlockList/BlockPhoneListItem';
import { WithFeature } from '../../../containers/Feature/WithFeature';
import { Download } from '../../../icons/common/CDownload';
import { forceDownload } from '../../../utils/downloads';
import { WithPermission } from '../../../containers/WithPermission/WithPermission';
import { theme } from '../../../theme/theme';
import { focusVisible } from '../../../theme/focusVisible';

interface Props {
    conversation: Conversation;
    onMuteClick?: () => void;
}

export function ConversationMenu(props: Props) {
    const track = useTrack();
    const menu = useMenu();
    const navigate = useNavigate();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { mutateAsync: deleteConvo, isPending } = useDeleteConversation();
    const { mutate: markAsRead } = useMarkConversationAsRead();
    const { mutate: markAsUnRead } = useMarkConversationAsUnRead();
    const { mutateAsync: downloadLogs, isPending: isExportPending } =
        useDownloadConversationLogs();
    const { mutateAsync: updateConvo, isPending: isUpdating } =
        useUpdateConversation();
    const unread = props.conversation.unreadMessageCount > 0;

    const exportLogs = () =>
        downloadLogs(props.conversation.id).then((blob) => {
            const file = window.URL.createObjectURL(blob);
            forceDownload(file, `${props.conversation.id}_${+new Date()}`);
        });

    return (
        <>
            <IconButton
                color="primary"
                onClick={menu.handleOpen}
                aria-label="Control menu"
                aria-controls={menu.open ? 'con-t-context-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={menu.open ? 'true' : undefined}
                data-navigation-element
                sx={{
                    '&.MuiButtonBase-root.MuiIconButton-root:focus-visible': {
                        ...focusVisible,
                        outlineOffset: '0px',
                        backgroundColor: theme.palette.passive.main,
                    },
                }}
            >
                <VerticalThreeDotsIcon />
            </IconButton>

            {menu.open && (
                <Menu
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorEl={menu.anchorEl}
                    open={menu.open}
                    onClose={() => menu.handleClose()}
                    MenuListProps={{
                        'aria-labelledby': 'con-t-context-menu',
                        sx: { minWidth: 172 },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            props.onMuteClick?.();
                        }}
                        data-testid="con-t-mute-btn"
                        disabled
                    >
                        <ListItemIcon>
                            <MuteIcon />
                        </ListItemIcon>
                        <ListItemText>Mute</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            if (unread) {
                                markAsRead(props.conversation.id);
                            } else {
                                markAsUnRead(props.conversation.id);
                            }
                        }}
                        data-testid="con-t-unread-btn"
                    >
                        <ListItemIcon>
                            <MarkAsUnreadIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {unread ? 'Mark as Read' : 'Mark as Unread'}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        disabled={isUpdating}
                        onClick={() => {
                            updateConvo({
                                id: props.conversation.id,
                                status:
                                    props.conversation.status ===
                                    ConversationStatus.Archived
                                        ? ConversationStatus.Active
                                        : ConversationStatus.Archived,
                            }).then(() => {
                                cleanByStatus(props.conversation);
                                track(
                                    props.conversation.status ===
                                        ConversationStatus.Archived
                                        ? 'conversation_unarchived'
                                        : 'conversation_archived',
                                    {
                                        origin: 'conversation menu',
                                        conversationId: props.conversation,
                                    },
                                );
                                navigate(
                                    `/inbox/${props.conversation.inboxId}`,
                                );
                            });
                        }}
                        data-testid="con-t-archive-btn"
                    >
                        <ListItemIcon>
                            {isUpdating ? (
                                <CircularProgress size={18} />
                            ) : (
                                <ArchiveIcon />
                            )}
                        </ListItemIcon>
                        <ListItemText>
                            {props.conversation.status ===
                            ConversationStatus.Archived
                                ? 'Un-Archive'
                                : 'Archive'}
                        </ListItemText>
                    </MenuItem>
                    <WithFeature feature={Feature.BlockList}>
                        <BlockPhoneListItem
                            phone={props.conversation.members}
                            onSuccess={() => {
                                navigate(
                                    `/inbox/${props.conversation.inboxId}`,
                                );
                            }}
                        />
                    </WithFeature>
                    <MenuItem
                        onClick={exportLogs}
                        data-testid="con-t-export-logs-btn"
                        disabled={
                            props.conversation.members.length > 1 ||
                            isExportPending
                        }
                    >
                        <ListItemIcon>
                            {isExportPending ? (
                                <CircularProgress
                                    color="error"
                                    size={16}
                                    sx={{ mr: 3 }}
                                />
                            ) : (
                                <Download />
                            )}
                        </ListItemIcon>
                        <ListItemText>Export Logs</ListItemText>
                    </MenuItem>
                    <WithPermission
                        requiredPermissions={[
                            ClerkPermission.DeleteConversation,
                        ]}
                    >
                        <Divider />
                        <MenuItem
                            disabled={isPending}
                            onClick={() => setIsDeleteModalOpen(true)}
                            sx={{ color: 'error.main' }}
                            data-testid="con-t-delete-btn"
                        >
                            {isPending ? (
                                <CircularProgress
                                    color="error"
                                    size={16}
                                    sx={{ mr: 3 }}
                                />
                            ) : (
                                <ListItemIcon>
                                    <DeleteIcon color="error" />
                                </ListItemIcon>
                            )}
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </WithPermission>
                </Menu>
            )}
            <Dialog
                open={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                maxWidth="xs"
            >
                <DialogAttentionIcon />
                <DialogTitleTwoLine
                    title="Confirm conversation deletion"
                    subtitle="Are you sure you want to delete the conversation?"
                />
                <DialogActions>
                    <Button
                        disabled={isPending}
                        sx={{ minWidth: 100 }}
                        onClick={() => setIsDeleteModalOpen(false)}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isPending}
                        startIcon={
                            isPending ? (
                                <CircularProgress color="error" size={18} />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        onClick={() => {
                            deleteConvo({
                                id: props.conversation.id,
                                inboxId: props.conversation.inboxId,
                                status: props.conversation.status,
                            }).then(() => {
                                setIsDeleteModalOpen(false);
                                navigate(
                                    `/inbox/${props.conversation.inboxId}`,
                                );
                            });
                        }}
                        variant="outlined"
                        color="error"
                        fullWidth
                    >
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
