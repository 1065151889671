import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../types/clerk-icon.props';

export const AddFilledIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 2.70833C10.3452 2.70833 10.625 2.98815 10.625 3.33333V9.37499H16.6667C17.0119 9.37499 17.2917 9.65482 17.2917 9.99999C17.2917 10.3452 17.0119 10.625 16.6667 10.625H10.625V16.6667C10.625 17.0118 10.3452 17.2917 10 17.2917C9.65486 17.2917 9.37504 17.0118 9.37504 16.6667V10.625H3.33337C2.9882 10.625 2.70837 10.3452 2.70837 9.99999C2.70837 9.65482 2.9882 9.37499 3.33337 9.37499H9.37504V3.33333C9.37504 2.98815 9.65486 2.70833 10 2.70833Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
