import { forwardRef, useMemo, useState } from 'react';
import { Conversation } from '../../../api/types';
import {
    useAssignUsers,
    useConversationUsers,
} from '../../../queries/conversations';
import { getFullName } from '../../../utils/user';
import {
    Box,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    InputBase,
    List,
    ListItemButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { AssignMemberIcon } from '../../../icons/common/CAssignMemberIcon';
import { useMenu } from '../../../hooks/use-menu';
import { ToolbarPopper } from '../ToolbarPopper/ToolbarPopper';
import { SearchIcon } from '../../../icons/common/SearchIcon';
import { UserAvatar } from '../../UserAvatar/UserAvatar';
import { CloseIcon } from '../../../icons/common/CCloseIcon';
import { SelectedCheckmarkIcon } from './SelectedCheckmarkIcon';
import { GroupedVirtuoso } from 'react-virtuoso';

import {
    alightLeftButton,
    divider,
    DIVIDER_HEIGHT,
    emptyDivider,
    item,
    ITEM_HEIGHT,
    list,
    searchInput,
    selectedItemAvatarCheckmark,
    selectedItemAvatarWrapper,
} from './styles';
import isEmpty from 'lodash/isEmpty';
import { TeammateSkeleton } from '../../Team/TeammateSkeleton';
import range from 'lodash/range';
import map from 'lodash/map';
import { Empty } from '../../Empty';
import { useTrack } from '../../../contexts/analytics';

const MAX_ITEMS = 7;

interface Props {
    conversation: Conversation;
}

export const AssignToTeam = ({ conversation }: Props) => {
    const menu = useMenu();
    const track = useTrack();
    const { mutate } = useAssignUsers();
    const [searchTerm, setSearchTerm] = useState('');
    const selected = map(conversation.assignee || [], 'id');
    const { data: teammates = [], isLoading } = useConversationUsers(
        conversation.id,
        {
            disabled: !menu.open,
            select: (profiles) =>
                profiles
                    .filter((profile) =>
                        [
                            `${profile.firstname} ${profile.lastname}`.trim(),
                            `${profile.lastname} ${profile.firstname}`.trim(),
                            profile.email,
                            profile.phone,
                        ].some((value) =>
                            value
                                ?.toLowerCase()
                                .replace(/\s+/g, ' ')
                                .includes(
                                    searchTerm
                                        .trim()
                                        .toLowerCase()
                                        .replace(/\s+/g, ' '),
                                ),
                        ),
                    )
                    .sort((a, b) => {
                        if (
                            selected.includes(a.id) &&
                            !selected.includes(b.id)
                        ) {
                            return -1;
                        }

                        if (
                            selected.includes(b.id) &&
                            !selected.includes(a.id)
                        ) {
                            return 1;
                        }

                        const aname = getFullName(a);
                        const bname = getFullName(b);
                        return aname.localeCompare(bname);
                    }),
        },
    );

    const [selectedAmount, freeAmount] = useMemo(() => {
        const filteredIds = teammates.map((user) => user.id);
        const filteredSelected = selected.filter((id) =>
            filteredIds.includes(id),
        );

        return [
            filteredSelected.length,
            teammates.length - filteredSelected.length,
        ];
    }, [selected, teammates]);

    const listHeight = useMemo(() => {
        const more =
            (!!selectedAmount && !!freeAmount ? DIVIDER_HEIGHT : 1) * 2;

        if (teammates.length > MAX_ITEMS) {
            return MAX_ITEMS * ITEM_HEIGHT + more;
        }

        return teammates.length * ITEM_HEIGHT + more;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teammates, selected]);

    const isNothingFound =
        !isLoading && isEmpty(teammates) && !!searchTerm.trim().length;

    return (
        <>
            <Tooltip title="Assign a team member">
                <IconButton
                    onClick={(e) => {
                        menu.handleOpen(e);
                        track('assign_team_member');
                    }}
                    color="primary"
                >
                    <AssignMemberIcon />
                </IconButton>
            </Tooltip>
            {menu.open && (
                <ToolbarPopper
                    anchorEl={menu.anchorEl}
                    handleClose={menu.handleClose}
                    header="Assign a team member"
                    disableContentPadding
                    ariaLabel="Assign a team member popper"
                >
                    <Stack>
                        <Box px={1}>
                            <InputBase
                                autoFocus
                                fullWidth
                                placeholder="Search"
                                sx={searchInput}
                                value={searchTerm}
                                onChange={(event) => {
                                    setSearchTerm(
                                        (event.target as HTMLInputElement)
                                            .value,
                                    );
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Divider sx={divider} />
                        {!isNothingFound && (
                            <Box px={1}>
                                <Button
                                    size="small"
                                    variant="text"
                                    fullWidth
                                    color="info"
                                    sx={alightLeftButton}
                                    onClick={() =>
                                        mutate({
                                            conversationId: conversation.id,
                                            userIds: selected.length
                                                ? []
                                                : map(teammates, 'id'),
                                        })
                                    }
                                >
                                    {selectedAmount
                                        ? 'Unassign all'
                                        : 'Assign all'}
                                </Button>
                            </Box>
                        )}
                    </Stack>

                    {isNothingFound && (
                        <Empty
                            title="Result is empty"
                            description={`Nothing found for "${searchTerm}"`}
                        />
                    )}
                    {isLoading &&
                        range(3).map((key) => (
                            <Box key={key} sx={{ px: 3, py: 1 }}>
                                <TeammateSkeleton />
                            </Box>
                        ))}

                    {!isEmpty(teammates) && (
                        <GroupedVirtuoso
                            groupCounts={[selectedAmount, freeAmount]}
                            style={{
                                height: `${listHeight}px`,
                            }}
                            components={{
                                List: forwardRef(
                                    function VirtuosoList(props, ref) {
                                        return (
                                            <List
                                                {...props}
                                                component="div"
                                                ref={ref}
                                                sx={list}
                                            />
                                        );
                                    },
                                ),
                            }}
                            groupContent={(index) => {
                                return (
                                    <Box sx={emptyDivider}>
                                        {index &&
                                        !!selectedAmount &&
                                        !!freeAmount ? (
                                            <Divider sx={divider} />
                                        ) : null}
                                    </Box>
                                );
                            }}
                            itemContent={(index) => {
                                if (teammates.length === 0) {
                                    return null;
                                }
                                const user = teammates[index];

                                if (!user) {
                                    return null;
                                }
                                const isSelected = selected.includes(user.id);
                                const fullName = [user.firstname, user.lastname]
                                    .join(' ')
                                    .trim();

                                const userName = fullName || user.email;
                                const avatar = (
                                    <UserAvatar
                                        size={20}
                                        imageUrl={user.avatar?.url}
                                        alt={userName}
                                        username={fullName}
                                    />
                                );

                                return (
                                    <ListItemButton
                                        selected={isSelected}
                                        onClick={() => {
                                            mutate({
                                                conversationId: conversation.id,
                                                userIds: isSelected
                                                    ? selected.filter(
                                                          (item) =>
                                                              item !== user.id,
                                                      )
                                                    : selected.concat(user.id),
                                            });
                                        }}
                                        sx={item}
                                    >
                                        <Stack
                                            direction="row"
                                            spacing={2}
                                            flex={1}
                                        >
                                            {isSelected ? (
                                                <Box
                                                    sx={
                                                        selectedItemAvatarWrapper
                                                    }
                                                >
                                                    {avatar}
                                                    <SelectedCheckmarkIcon
                                                        sx={
                                                            selectedItemAvatarCheckmark
                                                        }
                                                        aria-label="Selected checkmark"
                                                    />
                                                </Box>
                                            ) : (
                                                avatar
                                            )}

                                            <Typography
                                                variant="body3"
                                                noWrap
                                                flex={1}
                                                aria-label="Member name"
                                            >
                                                {userName}
                                            </Typography>
                                            {isSelected && (
                                                <IconButton
                                                    size="small"
                                                    aria-label="Un-assign button"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                        </Stack>
                                    </ListItemButton>
                                );
                            }}
                        />
                    )}
                </ToolbarPopper>
            )}
        </>
    );
};
