import {
    Contact,
    ExternalSourceContactProperty,
    IntegrationSource,
    IntegrationTypes,
} from '../../../api/types';
import {
    useGetIntegrationRemoteSchemaForEntityType,
    useListIntegrations,
} from '../../../queries/integrations';
import { useMemo } from 'react';
import { usePropertiesQuery } from '../../../queries/properties';

export const useIntegrationContactProperties = (
    contact: Partial<Contact>,
    source: IntegrationSource | null = null,
) => {
    const { data: existingProps = [] } = usePropertiesQuery({
        select: (properties) => {
            if (!source) {
                return [];
            }

            return properties.filter((prop) => prop.source === source);
        },
        enabled: !!source,
    });

    const { data: integrations = [] } = useListIntegrations({
        enabled: !!source,
    });

    const selectedIntegration = useMemo(
        () =>
            integrations.find(
                (item) =>
                    item.integrationSource === source &&
                    item.integrationType === IntegrationTypes.Contact,
            ),
        [integrations, source],
    );

    const { data: integrationSchema = [], isLoading } =
        useGetIntegrationRemoteSchemaForEntityType(
            selectedIntegration?.id,
            contact?.externalEntityType,
        );

    return useMemo(
        () => ({
            isLoading,
            properties: integrationSchema
                .filter(
                    (schemaProperty) =>
                        !existingProps.some(
                            (property) =>
                                property.externalId ===
                                    schemaProperty.externalId &&
                                property.source === schemaProperty.source,
                        ),
                )
                .map(
                    (prop): ExternalSourceContactProperty => ({
                        name: prop.name,
                        type: prop.type,
                        source: prop.source,
                        externalEntityType: prop.externalEntityType,
                        externalId: prop.externalId,
                        integrationId: prop.integrationId,
                        options: prop.options,
                    }),
                ),
        }),
        [existingProps, integrationSchema, isLoading],
    );
};
