export const propertiesPanel = {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    scrollbarWidth: 'none' /* Firefox */,
    msOverflowStyle: 'none' /* Internet Explorer 10+ */,
    '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
    },
};
