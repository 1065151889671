import { ChangeEvent, useCallback, useState } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import {
    usePermission,
    WithPermission,
} from '../../../containers/WithPermission/WithPermission';
import { ClerkPermission, Feature, RoleTypes } from '../../../api/types';
import InviteMsTeamsUserModal from '../../../components/Modals/InviteMsTeamsUserModal/InviteMsTeamsUserModal';
import { useTrack } from '../../../contexts/analytics';
import { SettingsLayout } from '../../../containers/SettingsLayout/SettingsLayout';
import { AddMemberIcon } from './AddMemberIcon';
import { SearchTextField } from '../../../elements/SearchTextField/SearchTextField';
import { Invites } from './Invites/Invites';
import { UserTable } from './Users/UserTable';
import { getCurrentUser, useEnabledFeature } from '../../../queries/user';
import { useNavigate } from 'react-router';
import { InviteUserModal } from '../../../components/Modals/InviteUserModal/InviteUserModal';
import { WithFeature } from '../../../containers/Feature/WithFeature';

export const Members = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showInviteModal, setShowInviteModal] = useState(false);
    const track = useTrack();
    const navigate = useNavigate();
    const canAdd = usePermission(ClerkPermission.AddTeamMembers);
    const canModify = usePermission(ClerkPermission.ModifyTeamMember);

    const canInviteTeammate = useEnabledFeature(Feature.InviteTeammate);
    const addMemberHandler = useCallback(() => {
        track('clicked_send_invite');
        canInviteTeammate
            ? setShowInviteModal(true)
            : navigate('/payment/plan');
    }, [canInviteTeammate, navigate, track]);

    const handleSuccessInvite = () => {
        setShowInviteModal(false);
    };

    const handleCloseModal = () => {
        setShowInviteModal(false);
    };

    const modal = () =>
        [RoleTypes.WorkspaceOwner, RoleTypes.Admin].includes(
            getCurrentUser()?.activeRole?.type as RoleTypes,
        ) ? (
            <InviteUserModal
                open={showInviteModal}
                onSuccess={handleSuccessInvite}
                onClose={handleCloseModal}
            />
        ) : (
            <InviteMsTeamsUserModal
                open={showInviteModal}
                onSuccess={handleSuccessInvite}
                onClose={handleCloseModal}
            />
        );

    return (
        <SettingsLayout
            title="Members"
            category="Workspace"
            description={
                canAdd || canModify
                    ? 'Manage members and users of your workspace and set their access level'
                    : 'View members and users of your workspace'
            }
        >
            <Stack spacing={10}>
                <Stack spacing={6}>
                    <Stack
                        spacing={1}
                        justifyContent="space-between"
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <WithFeature feature={Feature.InviteTeammate}>
                            <WithPermission
                                requiredPermissions={[
                                    ClerkPermission.AddTeamMembers,
                                ]}
                            >
                                <Button
                                    color="info"
                                    size="small"
                                    startIcon={<AddMemberIcon />}
                                    onClick={addMemberHandler}
                                    sx={{
                                        width: 152,
                                    }}
                                >
                                    Add member
                                </Button>
                            </WithPermission>
                        </WithFeature>

                        <SearchTextField
                            placeholder="Search teammate"
                            onChange={({
                                currentTarget,
                            }: ChangeEvent<HTMLInputElement>) =>
                                setSearchTerm(currentTarget.value)
                            }
                        />
                    </Stack>

                    <Divider />

                    <Invites searchTerm={searchTerm} />
                </Stack>
                <UserTable searchTerm={searchTerm} />
                {showInviteModal && modal()}
            </Stack>
        </SettingsLayout>
    );
};
