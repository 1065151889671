export interface Emoji {
    label: string;
    hexcode?: string;
    tags?: string[];
    order: number;
    group: number;
    unicode?: string;
}

export const getGroupedEmojiList = async (): Promise<
    Record<number, Emoji[]>
> => {
    const emojis = await import('emojibase-data/en/compact.json').then(
        ({ default: emojis }) => emojis,
    );
    return emojis
        .sort((a, b) => a.order - b.order)
        .reduce<Record<number, Emoji[]>>((acc, item) => {
            if (item.group !== null) {
                if (!acc[item.group]) {
                    acc[item.group] = [];
                }
                acc[item.group].push(item);
            }
            return acc;
        }, {});
};
