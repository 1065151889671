import React from 'react';
import Loading from '../../components/Loading/Loading';

const Lazy = React.lazy(() => import('./PaymentFlow'));

export const PaymentFlowPage = () => (
    <React.Suspense fallback={<Loading />}>
        <Lazy />
    </React.Suspense>
);
