import { AxiosError } from 'axios';
import {
    FetchQueryOptions,
    QueryObserverOptions,
    useInfiniteQuery,
    useMutation,
    useQuery,
} from '@tanstack/react-query';
import { queryClient } from './queryClient';
import { CONVERSATION_MESSAGES_KEY, UNREADS_COUNT_KEY } from './messages';
import { TEAMMATES_KEY, useMeQueryData } from './user';
import { deleteDataInPagedData } from './utils';
import {
    CONVERSATIONS_DEFAULT_FILTER,
    deleteConversation,
    findConversation,
    getCampaignConversations,
    getConversationForExternalContact,
    getConversationLogs,
    getConversationsPaged,
    getOrCreateConversation,
    markConversationAsRead,
    markConversationAsUnRead,
} from '../api/conversation';

import {
    Conversation,
    ConversationsFilter,
    ConversationStatus,
    Profile,
} from '../api/types';
import { PagedData } from '../types/PagedData';
import { UUID } from '../types/uuid';
import { getLatestQueryByKey } from '../utils/query-utils';
import { useContactsByPhones } from './contacts';
import { useTrack } from '../contexts/analytics';
import { client } from '../api/http';
import { formatPhoneNumber } from '../utils/phoneNumber';
import { useConversationSubscription } from '../websocket/use-conversations-subscription';
import map from 'lodash/map';

export const CONVERSATION_KEY = 'conversation';
export const CONVERSATIONS_LIST_KEY = 'conversations';
const CONVERSATION_SEARCH_KEY = 'conversation_search';
const DOWNLOAD_CONVERSATION_LOGS_KEY = 'download_conversation_logs';

const getConversationOptions = (id: UUID): FetchQueryOptions<Conversation> => ({
    queryKey: [CONVERSATION_KEY, id],
    queryFn: () =>
        client
            .get<Conversation>(`/conversations/${id}`)
            .then(({ data }) => data),
});

export const useConversation = (id: UUID | undefined, enabled = true) => {
    return useQuery({
        ...getConversationOptions(id!),
        enabled: enabled && !!id,
        staleTime: 5_000,
    });
};

export const prefetchConversation = (id: UUID) =>
    queryClient.prefetchQuery({
        ...getConversationOptions(id),
        staleTime: 15_000,
    });

export const useGetOrCreateConversation = () =>
    useMutation({
        mutationFn: getOrCreateConversation,
    });

export const useUpdateConversation = () => {
    const track = useTrack();

    return useMutation({
        mutationKey: ['update_conversation'],
        mutationFn: (conversation: Partial<Conversation>) =>
            client
                .post<Conversation>(
                    `/conversations/${conversation.id}`,
                    conversation,
                )
                .then(({ data }) => data),
        onSuccess: (data) => {
            track('conversation_updated');
            queryClient.refetchQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
                exact: false,
            });
            queryClient.setQueryData<Conversation>(
                [CONVERSATION_KEY, data.id],
                (prev) => (prev ? { ...prev, ...data } : prev),
            );
        },
    });
};

export const useDownloadConversationLogs = () => {
    const track = useTrack();
    return useMutation({
        mutationKey: [DOWNLOAD_CONVERSATION_LOGS_KEY],
        mutationFn: getConversationLogs,
        onSuccess: (_, conversationId) => {
            track('conversation_logs_downloaded', {
                conversationId,
            });
        },
    });
};

export const useDeleteConversation = () => {
    const track = useTrack();
    return useMutation<
        Conversation,
        AxiosError,
        { id: string; inboxId: string; status: ConversationStatus },
        { id: string; inboxId: string; status: ConversationStatus }
    >({
        mutationFn: (p) => deleteConversation(p.id),
        onSuccess: (data, variables) => {
            track('conversation_deleted', {
                id: data.id,
                status: data.status,
            });
            queryClient.removeQueries({
                queryKey: [CONVERSATION_MESSAGES_KEY, variables.id],
            });
            queryClient.removeQueries({
                queryKey: [CONVERSATION_KEY, variables.id],
            });
            queryClient.setQueriesData<PagedData<Conversation>>(
                { queryKey: [CONVERSATIONS_LIST_KEY, variables.inboxId] },
                (prev) => {
                    if (!prev) {
                        return;
                    }
                    const pages = prev.pages.map((page) =>
                        page.filter(
                            (conversation) => conversation.id !== variables.id,
                        ),
                    );
                    return {
                        ...prev,
                        pages,
                    };
                },
            );
        },
        onMutate: (variables) => {
            const queryData = queryClient.getQueryData<PagedData<Conversation>>(
                [CONVERSATIONS_LIST_KEY, variables.inboxId, variables.status],
            );
            if (queryData) {
                const updated = deleteDataInPagedData<Conversation>(
                    queryData,
                    (c) => c.id === variables.id,
                );
                queryClient.setQueryData(
                    [
                        CONVERSATIONS_LIST_KEY,
                        variables.inboxId,
                        variables.status,
                    ],
                    updated,
                );
            }
            return variables;
        },
        onError: (_, context) => {
            queryClient.invalidateQueries({
                queryKey: [
                    CONVERSATIONS_LIST_KEY,
                    context.inboxId,
                    context.status,
                ],
            });
        },
    });
};

export const useMarkConversationAsRead = () =>
    useMutation<void, unknown, string, unknown>({
        mutationKey: ['markConversationAsRead'],
        mutationFn: markConversationAsRead,
        onSuccess: (_, conversationId) => {
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
            queryClient.invalidateQueries({ queryKey: [UNREADS_COUNT_KEY] });
            queryClient.setQueryData<Conversation>(
                [CONVERSATION_KEY, conversationId],
                (prev) => {
                    if (prev) {
                        return { ...prev, unreadMessageCount: 0 };
                    }
                    return prev;
                },
            );
        },
    });

export const useMarkConversationAsUnRead = () =>
    useMutation<void, unknown, string, unknown>({
        mutationKey: ['markConversationAsUnRead'],
        mutationFn: markConversationAsUnRead,
        onSuccess: (_, conversationId) => {
            queryClient.invalidateQueries({
                queryKey: [CONVERSATIONS_LIST_KEY],
            });
            queryClient.invalidateQueries({ queryKey: [UNREADS_COUNT_KEY] });
            queryClient.setQueryData<Conversation>(
                [CONVERSATION_KEY, conversationId],
                (prev) => {
                    if (prev) {
                        return {
                            ...prev,
                            unreadMessageCount:
                                (prev.unreadMessageCount ?? 0) + 1,
                        };
                    }
                    return prev;
                },
            );
        },
    });

/** @deprecated */
export const useGetConversationName = () => {
    const countryCode = useMeQueryData()?.activeTeam?.countryCode ?? 'US';
    const getContactsOrEmptyByPhones = useContactsByPhones();

    return (conversation: Conversation) =>
        getContactsOrEmptyByPhones(conversation.members)
            .map(
                (contact) =>
                    contact?.name ||
                    formatPhoneNumber(contact?.phone, countryCode),
            )
            .filter(Boolean)
            .join(', ')
            .trim() || 'Conversation';
};

export const useInboxConversations = (
    inboxId: UUID,
    filter?: ConversationsFilter,
) => {
    const query = useInfiniteQuery({
        queryKey: [CONVERSATIONS_LIST_KEY, inboxId, filter],
        enabled: !!inboxId,
        initialPageParam: undefined,
        select: (data) => data.pages.flat() ?? [],
        queryFn: ({ pageParam }) =>
            getConversationsPaged(
                inboxId,
                filter ?? CONVERSATIONS_DEFAULT_FILTER,
                pageParam,
            ),
        getNextPageParam: (lastPage: Conversation[]): number | undefined => {
            if (lastPage && lastPage?.length > 0) {
                return new Date(
                    lastPage[lastPage?.length - 1].updated,
                ).getTime();
            }
            return undefined;
        },
    });
    useConversationSubscription(map(query.data, 'id'));

    return query;
};

export const useGetCampaignConversations = (
    campaignId: number,
    enabled = true,
) => {
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery<Conversation[]>({
            queryKey: [CONVERSATIONS_LIST_KEY, 'campaign', campaignId],
            queryFn: ({ pageParam }) =>
                getCampaignConversations(campaignId, pageParam),
            initialPageParam: undefined,
            enabled,
            refetchIntervalInBackground: false,
            getNextPageParam: (lastPage) =>
                lastPage &&
                lastPage.length > 0 &&
                new Date(lastPage[lastPage.length - 1].updated).getTime(),
        });

    const conversations =
        data?.pages.reduce((acc, msgs) => acc.concat(msgs), []) || [];

    return {
        conversations,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isPending,
    };
};

type AssignParams = {
    conversationId: UUID;
    userIds: UUID[];
};
export const useAssignUsers = () => {
    const track = useTrack();

    return useMutation({
        mutationFn: ({ conversationId, userIds }: AssignParams) =>
            client
                .post<
                    Omit<Conversation, 'inbox'>
                >(`/conversations/${conversationId}/assign`, { userIds })
                .then(({ data }) => data),
        onMutate: ({ conversationId, userIds = [] }) => {
            const conversation = queryClient.getQueryData<Conversation>([
                CONVERSATION_KEY,
                conversationId,
            ]);

            const users =
                queryClient.getQueryData<Profile[]>([TEAMMATES_KEY]) || [];

            if (conversation) {
                const assignedUsers = users.filter((user) =>
                    userIds.includes(user.id),
                );
                queryClient.setQueryData<Conversation>(
                    [CONVERSATION_KEY, conversationId],
                    { ...conversation, assignee: assignedUsers },
                );
            }
            return { prevConversation: conversation };
        },
        onError: (_, data, context) => {
            const { prevConversation } = context || {};

            if (prevConversation) {
                queryClient.setQueryData<Conversation>(
                    [CONVERSATION_KEY, data.conversationId],
                    prevConversation,
                );
            }
        },
        onSuccess: (updated) => {
            track('conversation_user_assigned', {
                assignedCount: updated.assignee?.length || 0,
            });
            const query = getLatestQueryByKey(queryClient, [
                CONVERSATIONS_LIST_KEY,
            ]);
            queryClient.invalidateQueries({ queryKey: query.queryKey });
            queryClient.setQueryData<Conversation>(
                [CONVERSATION_KEY, updated.id],
                (prev) => (prev ? { ...prev, ...updated } : prev),
            );
        },
    });
};

export const useConversationUsers = (
    conversationId: string,
    options?: {
        disabled?: boolean;
        select?: QueryObserverOptions<Profile[]>['select'];
    },
) =>
    useQuery({
        queryKey: ['conversation_users', conversationId],
        queryFn: () =>
            client
                .get<Profile[]>(`/conversations/${conversationId}/users`)
                .then(({ data }) => data),
        enabled: !options?.disabled,
        select: options?.select,
    });

export function useConversationFindQuery(
    inboxId: UUID,
    contactIds: (UUID | string)[],
) {
    return useQuery({
        queryKey: [CONVERSATION_SEARCH_KEY, { inboxId, contactIds }],
        queryFn: () => findConversation({ inboxId, contactIds }),
        // It's necessary to keep `retry: false`. If to remove this setting, react-query will attempt
        // to retry failed search request that may lead to the case when UI and actual query are not synced.
        retry: false,
        enabled: !!contactIds.length && !!inboxId,
    });
}

export function useSummarizeConversationQuery(
    conversationId: UUID,
    enabled: boolean,
) {
    return useQuery({
        queryKey: [CONVERSATION_SEARCH_KEY, { conversationId }],
        queryFn: () =>
            client
                .get<string>(`/ai/conversation-summary/${conversationId}`)
                .then(({ data }) => data),
        retry: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        gcTime: Infinity,
        enabled,
    });
}

export const cleanByStatus = (
    conversation: Pick<Conversation, 'id' | 'status' | 'inboxId'>,
) =>
    queryClient.setQueryData<PagedData<Conversation>>(
        [
            CONVERSATIONS_LIST_KEY,
            conversation.inboxId,
            { type: conversation.status },
        ],
        (prev) => {
            if (!prev) {
                return prev;
            }
            return {
                ...prev,
                pages: prev.pages.map((page) =>
                    page.filter(({ id }) => id !== conversation.id),
                ),
            };
        },
    );

export const useConversationForExternalContact = (externalId: string | null) =>
    useQuery({
        queryKey: ['conversation-for-external-contact', externalId],
        queryFn: () => getConversationForExternalContact(externalId!),
        enabled: !!externalId,
        retry: false,
    });
