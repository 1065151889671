import { ClerkContactProperty, ContactPropertyType } from '../../api/types';
import { PropertyIcon } from '../../icons/iconsMap';
import { getTypeIcon } from './PropertyIcon/type-icon.factory';

export const propertyTypeIconNameMap: Record<
    ContactPropertyType,
    PropertyIcon
> = {
    [ContactPropertyType.Text]: 'text',
    [ContactPropertyType.Number]: 'number',
    [ContactPropertyType.Select]: 'select',
    [ContactPropertyType.MultiSelect]: 'select',
    [ContactPropertyType.Date]: 'created-time',
    [ContactPropertyType.Person]: 'private',
    [ContactPropertyType.URL]: 'url',
    [ContactPropertyType.Email]: 'email-2',
    [ContactPropertyType.Phone]: 'phone',
    [ContactPropertyType.CreatedTime]: 'created-time',
    [ContactPropertyType.LastEditedTime]: 'updated',
    [ContactPropertyType.Boolean]: 'boolean',
    [ContactPropertyType.FilesAndMedia]: '',
    [ContactPropertyType.LastContactedBy]: '',
    [ContactPropertyType.LastEditedBy]: '',
};

export const contactPropertyTypeMap: ClerkContactProperty[][] = [
    [
        {
            type: ContactPropertyType.Phone,
            name: 'Phone',
            icon: getTypeIcon(ContactPropertyType.Phone),
        },
        {
            type: ContactPropertyType.Email,
            name: 'Email',
            icon: getTypeIcon(ContactPropertyType.Email),
        },
    ],
    [
        {
            type: ContactPropertyType.Text,
            name: 'Text',
            icon: getTypeIcon(ContactPropertyType.Text),
        },
        {
            type: ContactPropertyType.Number,
            name: 'Number',
            icon: getTypeIcon(ContactPropertyType.Number),
        },
        {
            type: ContactPropertyType.Boolean,
            name: 'Boolean',
            icon: getTypeIcon(ContactPropertyType.Boolean),
        },
        {
            type: ContactPropertyType.Select,
            name: 'Select',
            icon: getTypeIcon(ContactPropertyType.Select),
        },
        {
            type: ContactPropertyType.MultiSelect,
            name: 'Multi-select',
            icon: getTypeIcon(ContactPropertyType.MultiSelect),
        },
        {
            type: ContactPropertyType.Date,
            name: 'Date',
            icon: getTypeIcon(ContactPropertyType.Date),
        },
        {
            type: ContactPropertyType.Person,
            name: 'Person',
            icon: getTypeIcon(ContactPropertyType.Person),
        },
        {
            type: ContactPropertyType.URL,
            name: 'URL',
            icon: getTypeIcon(ContactPropertyType.URL),
        },
        {
            type: ContactPropertyType.CreatedTime,
            name: 'Created time',
            icon: getTypeIcon(ContactPropertyType.CreatedTime),
        },
        {
            type: ContactPropertyType.LastEditedTime,
            name: 'Last edited time',
            icon: getTypeIcon(ContactPropertyType.LastEditedTime),
        },
    ],
];
