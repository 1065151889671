import { Box, Popover } from '@mui/material';
import { ContactPropertyType, Feature } from '../../../api/types';
import {
    contactPropertyTypeMap,
    propertyTypeIconNameMap,
} from '../../../components/ContactProperty/contactPropertyTypeMap';
import { useState } from 'react';
import { useCreateProperty } from '../../../queries/properties';
import { MenuProps } from '../../../hooks/use-menu';
import { useEnabledFeature } from '../../../queries/user';
import { useCsvImportContext } from './CsvImportContext';
import { PropertyConfigurator } from '../../../components/ContactProperty/AddNewProperty/PropertyConfigurator/PropertyConfigurator';
import { PropertiesList } from '../../../components/ContactProperty/AddNewProperty/PropertiesList';

type Props = {
    menu: MenuProps;
};

export const NewPropertyPopover = ({ menu }: Props) => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const { addPhoneNumberField, newPhoneNumberFields } = useCsvImportContext();
    const handleClose = () => {
        menu.handleClose();
        window.setTimeout(() => {
            setSelectedType(null);
        }, 200);
    };

    const [selectedType, setSelectedType] =
        useState<ContactPropertyType | null>(null);

    const { mutateAsync: createProperty, isPending } = useCreateProperty();
    const [error, setError] = useState<string | null>(null);

    return (
        <Popover
            open={menu.open}
            anchorEl={menu.anchorEl}
            onClose={handleClose}
            slotProps={{
                paper: {
                    elevation: 5,
                    sx: {
                        borderRadius: 1.5,
                        margin: 0,
                        border: '1px solid',
                        borderColor: 'custom.gray.divider',
                    },
                },
            }}
        >
            <Box maxWidth={196}>
                {selectedType ? (
                    <PropertyConfigurator
                        error={error}
                        type={selectedType}
                        isPending={isPending}
                        onNameChange={(name) => {
                            setSelectedType(null);

                            if (
                                selectedType === ContactPropertyType.Phone &&
                                isMultipleContactPhonesEnabled
                            ) {
                                if (
                                    newPhoneNumberFields.some(
                                        (f) => f.name === name,
                                    )
                                ) {
                                    setError(
                                        'Phone number with this name exists',
                                    );
                                    return;
                                }

                                addPhoneNumberField({
                                    name,
                                    icon: propertyTypeIconNameMap[selectedType],
                                });
                                handleClose();
                                return;
                            }

                            createProperty({
                                name,
                                type: selectedType,
                                icon: propertyTypeIconNameMap[selectedType],
                            }).then(handleClose);
                        }}
                        onClose={handleClose}
                    />
                ) : (
                    <PropertiesList
                        properties={contactPropertyTypeMap}
                        onSelectProperty={(property) => {
                            if ('type' in property) {
                                setSelectedType(
                                    property.type as ContactPropertyType,
                                );
                            }
                        }}
                    />
                )}
            </Box>
        </Popover>
    );
};
