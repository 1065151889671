import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';

export const CloseFilledIcon = (props: Exclude<SvgIconProps, 'component'>) => (
    <SvgIcon inheritViewBox {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332ZM7.1486 6.4643C6.95964 6.27534 6.65327 6.27534 6.4643 6.4643C6.27534 6.65327 6.27534 6.95964 6.4643 7.1486L9.31554 9.99984L6.4643 12.8511C6.27534 13.04 6.27534 13.3464 6.4643 13.5354C6.65327 13.7243 6.95964 13.7243 7.1486 13.5354L9.99984 10.6841L12.8511 13.5354C13.04 13.7243 13.3464 13.7243 13.5354 13.5354C13.7243 13.3464 13.7243 13.04 13.5354 12.8511L10.6841 9.99984L13.5354 7.1486C13.7243 6.95964 13.7243 6.65327 13.5354 6.4643C13.3464 6.27534 13.04 6.27534 12.8511 6.4643L9.99984 9.31554L7.1486 6.4643Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
