import { contactPropertyTypeMap } from '../contactPropertyTypeMap';
import { useMemo } from 'react';
import {
    Contact,
    IntegrationContactProperty,
    IntegrationSource,
    IntegrationTypes,
    NewContactProperty,
} from '../../../api/types';
import { availableIntegrations } from '../../../main/settings/Integrations/integrations-list';
import { Box } from '@mui/material';
import { getSquare } from '../../../theme/style.helpers';
import { useListIntegrations } from '../../../queries/integrations';
import { useIntegrationContactProperties } from './use-integration-contact-properties';

type Props = {
    contact: Partial<Contact>;
    source: IntegrationSource | null;
};

export const useContactProperties = ({ contact, source }: Props) => {
    const { data: contactIntegrations = [] } = useListIntegrations({
        enabled: !!source,
        select: (integrations) =>
            integrations.filter(
                (integration) =>
                    integration.integrationType === IntegrationTypes.Contact,
            ),
    });

    const { properties: integrationProperties, isLoading } =
        useIntegrationContactProperties(contact, source);

    return useMemo((): {
        isLoading: boolean;
        properties: NewContactProperty[][];
    } => {
        if (!isLoading && source) {
            return {
                isLoading,
                properties: [integrationProperties],
            };
        }

        const externalProps = contactIntegrations
            .filter(
                (item) =>
                    item.integrationSource === contact.externalSource &&
                    availableIntegrations.some(
                        (availableItem) =>
                            availableItem.source === item.integrationSource,
                    ),
            )
            .map((item): IntegrationContactProperty => {
                const integration = availableIntegrations.find(
                    (availableItem) =>
                        availableItem.source === item.integrationSource,
                );

                return {
                    name: integration!.name,
                    source: integration!.source,
                    icon: (
                        <Box
                            component="img"
                            src={integration?.icon}
                            alt={integration?.name}
                            sx={getSquare(20)}
                        />
                    ),
                };
            });

        return {
            isLoading,
            properties: externalProps.length
                ? [...contactPropertyTypeMap, externalProps]
                : contactPropertyTypeMap,
        };
    }, [
        contact.externalSource,
        contactIntegrations,
        integrationProperties,
        isLoading,
        source,
    ]);
};
