import {
    Box,
    Divider,
    ListItem,
    ListItemIcon,
    MenuItem,
    Typography,
} from '@mui/material';
import { SearchTextField } from '../../../elements/SearchTextField/SearchTextField';
import { ChangeEvent, useMemo, useState } from 'react';
import { ChevronRightIcon } from '../../../icons/common/CChevronRightIcon';
import { getSquare } from '../../../theme/style.helpers';
import { NewContactProperty } from '../../../api/types';
import { Header } from './Header';
import Loading from '../../Loading/Loading';

type Props = {
    isLoading?: boolean;
    sourceName?: string;
    properties: NewContactProperty[][];
    onSelectProperty: (item: NewContactProperty) => void;
};

export const PropertiesList = ({
    isLoading = false,
    properties,
    sourceName,
    onSelectProperty,
}: Props) => {
    const [term, setTerm] = useState('');

    const filteredGroups = useMemo(
        () =>
            properties.reduce((acc, group) => {
                const filteredGroup = group.filter((item) =>
                    item.name?.toLowerCase().includes(term.toLowerCase()),
                );

                if (!filteredGroup.length) {
                    return acc;
                }

                return [...acc, filteredGroup];
            }, [] as NewContactProperty[][]),
        [properties, term],
    );

    return (
        <Box py={1}>
            {sourceName && <Header title={sourceName} />}
            <ListItem sx={{ px: 3 }}>
                <SearchTextField
                    autoFocus
                    value={term}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setTerm(event.target.value);
                    }}
                />
            </ListItem>

            <Box
                sx={[
                    !!sourceName && {
                        maxHeight: 437,
                        overflowY: 'auto',
                    },
                ]}
            >
                {isLoading ? (
                    <Box py={4}>
                        <Loading />
                    </Box>
                ) : (
                    filteredGroups.map((group, idx) => (
                        <>
                            {!!idx && <Divider key={`divider-${idx}`} />}
                            {group.map((item) => {
                                return (
                                    <MenuItem
                                        key={item.name}
                                        onClick={() => onSelectProperty(item)}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        {'icon' in item && item.icon && (
                                            <ListItemIcon sx={getSquare(20)}>
                                                {item.icon}
                                            </ListItemIcon>
                                        )}
                                        <Typography
                                            variant="body3"
                                            color="primary.dark"
                                            noWrap
                                            flex={1}
                                        >
                                            {item.name}
                                        </Typography>
                                        {'source' in item &&
                                            item.source &&
                                            !('type' in item) && (
                                                <ChevronRightIcon
                                                    sx={[
                                                        getSquare(20),
                                                        {
                                                            color: 'custom.gray.super',
                                                        },
                                                    ]}
                                                />
                                            )}
                                    </MenuItem>
                                );
                            })}
                        </>
                    ))
                )}
            </Box>
        </Box>
    );
};
