import { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';

export const MuiMenu: Components<Theme>['MuiMenu'] = {
    defaultProps: {
        disableAutoFocusItem: true,
        slotProps: {
            paper: {
                elevation: 5,
            },
        },
    },
    styleOverrides: {
        paper: ({ theme }) => ({
            margin: theme.spacing(2, 0),
            borderRadius: theme.spacing(1.5),
            border: '1px solid',
            borderColor: theme.palette.custom.gray.tableDivider,
            p: 0,
        }),
        list: ({ theme }) => ({
            padding: theme.spacing(1, 0),
        }),
    },
};
