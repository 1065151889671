import { useEffect, useState } from 'react';
import { Table, TableCell, TableRow } from '../../components/Table';
import { UserPropertyModel } from '../../components/UserInfoProperty/types';
import CheckBox from '../../elements/CheckBox/CheckBox';
import {
    useGetIntegrationRemoteSchema,
    useListIntegrations,
} from '../../queries/integrations';
import {
    useCreateProperty,
    useDeleteProperty,
    usePropertiesQuery,
} from '../../queries/properties';
import { PropertySource } from '../../components/ContactProperty/types';
import { useParams } from 'react-router';
import { Column } from '../../api/types';

const mapPropsToSchema = (
    properties: UserPropertyModel[],
    schema: Column[],
): Column[] => {
    return schema.map((column) => {
        const prop = properties.find((p) => p.externalId === column.externalId);
        if (prop) {
            return {
                ...column,
                id: prop.id,
                propertyType: prop.type,
                usage: 'existing-property',
                itemType: 'property',
            };
        }
        return { ...column, usage: 'skip' };
    });
};

export const IntegrationPropertyMap = () => {
    const { integrationId } = useParams<{
        integrationId: string;
    }>();
    const { data: remoteSchema = [], isLoading: remoteLoading } =
        useGetIntegrationRemoteSchema(integrationId!);
    const { data: properties = [], isLoading: propertiesLoading } =
        usePropertiesQuery();
    const { mutateAsync: createProperty } = useCreateProperty();
    const { mutateAsync: deleteProperty } = useDeleteProperty();
    const { data: integrations = [] } = useListIntegrations();

    const [columns, setColumns] = useState<Column[]>([]);

    const integration = integrations?.find((i) => i.id === integrationId);

    useEffect(() => {
        if (!remoteLoading && !propertiesLoading) {
            setColumns(mapPropsToSchema(properties, remoteSchema));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteLoading, propertiesLoading, properties, integrations]);

    return (
        <Table>
            <TableRow isHead>
                <TableCell width="200px">
                    <span>Columns from {integration?.integrationSource}</span>
                </TableCell>
                <TableCell>
                    <span>Sync</span>
                </TableCell>
            </TableRow>
            {columns.map((column) => (
                <TableRow key={column.id}>
                    <TableCell width="200px">
                        <span>{column.propertyName}</span>
                    </TableCell>
                    <TableCell width="275px">
                        <CheckBox
                            checked={column.usage === 'existing-property'}
                            onChange={() => {
                                if (column.id) {
                                    deleteProperty(column.id);
                                } else {
                                    createProperty({
                                        name: column.propertyName,
                                        type: column.propertyType,
                                        externalId: column.externalId,
                                        readonly: true,
                                        options: column.options,
                                        // @ts-ignore
                                        source: integration?.integrationSource as PropertySource,
                                    });
                                }
                            }}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    );
};
