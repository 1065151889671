import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ExtendedUserPropertyModel, PropertyRow } from './PropertyRow';
import { usePropertiesQuery } from '../../../queries/properties';
import { useCsvImportContext } from './CsvImportContext';
import { useDefaultFields } from './use-default-fields';
import { CreatePropertyMenu } from './CreatePropertyMenu';
import { pluralize } from '../../../utils/pluralize';
import { PropertySource } from '../../../components/ContactProperty/types';

export const CsvMapping = () => {
    const defaultFields = useDefaultFields();
    const { enabled, numOfLines, newPhoneNumberFields } = useCsvImportContext();
    const { data: properties = [] } = usePropertiesQuery();

    const userProperties = useMemo<ExtendedUserPropertyModel[]>(() => {
        return [
            ...defaultFields,
            ...newPhoneNumberFields,
            ...properties.filter(
                (property) => property.source === PropertySource.Clerk,
            ),
        ];
    }, [defaultFields, newPhoneNumberFields, properties]);

    return (
        <Stack spacing={6}>
            <Typography variant="body2" color="primary.dark" fontWeight={500}>
                Data preview{' '}
                <Typography variant="body4" color="text.disabled">
                    ({pluralize(numOfLines, 'contact', 'contacts')} found)
                </Typography>
            </Typography>

            <Stack spacing={4}>
                {userProperties.map((property) => {
                    return (
                        <PropertyRow key={property.id} property={property} />
                    );
                })}
            </Stack>
            {enabled && <CreatePropertyMenu />}
        </Stack>
    );
};
