import * as styles from '../SettingsLayout/styles';
import { useSidebar } from '../../contexts/sidebar';
import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { useIsBannerShown } from '../../main/settings/Compliance/useIsBannerShown';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';
import { ChevronLeftDouble } from '../../icons/common/ChevronLeftDouble';
import { ChevronRightDouble } from '../../icons/common/ChevronRightDouble';
import { getSquare } from '../../theme/style.helpers';
import { shadows } from '../../theme/shadows';
import { useState } from 'react';
import { useBetweenMdLg } from '../../hooks/useBetweenMdLg';

const MOBILE_BAR_HEIGHT = 60;

export function SidebarDrawer({ children }: { children: React.ReactNode }) {
    const [showToggleButton, setShowToggleButton] = useState(false);
    const { isCollapsed, isOpened, toggleCollapse } = useSidebar();
    const isNarrowScreen = useBetweenMdLg();
    const isMobile = useIsDownMd();
    const isBannerShown = useIsBannerShown();
    const mobileOffsetTop = isBannerShown
        ? MOBILE_BAR_HEIGHT + BANNER_HEIGHT
        : MOBILE_BAR_HEIGHT;
    const isDetached = !isCollapsed && isNarrowScreen;

    if (isMobile) {
        return (
            <Box
                sx={(theme) => ({
                    width: 1,
                    height: isBannerShown
                        ? `calc(100% - ${mobileOffsetTop}px)`
                        : 1,
                    maxHeight: `calc(100vh - ${mobileOffsetTop}px)`,
                    top: mobileOffsetTop,
                    background: theme.palette.background.paper,
                    overflowWrap: 'anywhere',
                    position: 'absolute',
                    zIndex: theme.zIndex.drawer,
                    display: isOpened ? 'block' : 'none',
                })}
            >
                {children}
            </Box>
        );
    }

    return (
        <Drawer
            open={!isMobile || isOpened}
            variant={isDetached ? 'persistent' : 'permanent'}
            PaperProps={{
                sx: [
                    {
                        height: isBannerShown
                            ? `calc(100% - ${BANNER_HEIGHT}px)`
                            : 1,
                        top: isBannerShown ? BANNER_HEIGHT : 0,
                        overflow: 'unset',
                    },
                    isCollapsed && styles.collapsedSidebar,
                ],
                elevation: isDetached ? 5 : 0,
            }}
            sx={{
                width: [1, isCollapsed ? 72 : 248],
            }}
            anchor="left"
            onMouseEnter={() => setShowToggleButton(true)}
            onMouseLeave={() => setShowToggleButton(false)}
        >
            <Box
                sx={{
                    position: 'relative',
                    flex: 1,
                }}
            >
                {children}
                {showToggleButton && (
                    <Tooltip
                        title={isCollapsed ? 'Expand' : 'Collapse'}
                        placement="right"
                        disableFocusListener
                    >
                        <IconButton
                            onClick={toggleCollapse}
                            size="small"
                            sx={{
                                ...getSquare(24),
                                boxShadow: shadows[1],
                                border: '1px solid',
                                borderColor: 'custom.gray.tableDivider',
                                background: 'white',
                                borderRadius: '50%',
                                position: 'absolute',
                                top: 18,
                                right: -12,
                                zIndex: (theme) => theme.zIndex['drawer'] + 1,
                            }}
                            data-navigation-element
                        >
                            {isCollapsed ? (
                                <ChevronRightDouble />
                            ) : (
                                <ChevronLeftDouble />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        </Drawer>
    );
}
