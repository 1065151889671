import { usePhoneNumberFormatter } from '../../../queries/user';
import { ContactPropertyType } from '../../../api/types';
import { useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';

export const COL_WIDTH = 248;

export function usePreviewFormatter() {
    const phoneFormatter = usePhoneNumberFormatter();

    return useCallback(
        (preview: string, id: string, type?: ContactPropertyType) => {
            if (id === 'name') {
                return (
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <UserAvatar
                            size={20}
                            alt={preview}
                            username={preview}
                        />

                        <Typography
                            variant="body3"
                            color="primary.dark"
                            display="inline-block"
                            maxWidth={COL_WIDTH - 52 /* paddingX + avatar */}
                            noWrap
                            title={preview}
                        >
                            {preview}
                        </Typography>
                    </Stack>
                );
            }

            if (type === ContactPropertyType.Phone) {
                return phoneFormatter(preview);
            }

            return preview;
        },
        [phoneFormatter],
    );
}
