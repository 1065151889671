/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
    OnOptionCreateHandler,
    OnPropertyChangeHandler,
    OnPropertyDeleteHandler,
    UserPropertyModel,
} from '../types';
import { renderValue } from '../UserPropertyValue';
import { Icon } from '../../../icons/Icon';
import PropertyIconEditor from '../../ContactProperty/PropertyIconEditor';
import { PropertyConfigurator } from '../../ContactProperty/AddNewProperty/PropertyConfigurator/PropertyConfigurator';
import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { useFilterMeta } from '../../Contacts/Cohorts/hooks';
import { getPropertyIcon } from '../../../utils/contact-properties';
import { useLocation } from 'react-router';
import { ContactPropertyType } from '../../../api/types';
import { useMenu } from '../../../hooks/use-menu';
import { PropertySource } from '../../ContactProperty/types';
import { ExternalPropertyConfigurator } from '../../ContactProperty/AddNewProperty/PropertyConfigurator/ExternalPropertyConfigurator';

interface UserPropertyProps {
    contactId?: string;
    userProperty: UserPropertyModel;
    nameEditMode?: boolean;
    valueTransformer?: (value: any) => any;
    onPropertyChange: OnPropertyChangeHandler;
    onValueChange: (value: any) => any;
    onDelete?: OnPropertyDeleteHandler;
    onOptionCreate?: OnOptionCreateHandler;
}

function UserProperty({
    contactId,
    userProperty,
    nameEditMode = false,
    valueTransformer,
    onPropertyChange,
    onValueChange,
    onDelete,
    onOptionCreate,
}: UserPropertyProps) {
    const menu = useMenu();

    const { pathname } = useLocation();
    const isInbox = pathname.startsWith('/inbox/');
    const getFilterMeta = useFilterMeta();
    const filterMeta = getFilterMeta(userProperty);
    const [iconEditorActive, setIconEditorActive] = useState(false);
    const [nameEditorActive, setNameEditorActive] = useState(nameEditMode);
    const handleClose = () => {
        menu.handleClose();
        window.setTimeout(() => {
            setIconEditorActive(false);
            setNameEditorActive(false);
        }, 200);
    };

    const handleDelete = () => {
        onDelete?.(userProperty);
        handleClose();
    };

    const canEditIcon =
        !filterMeta.readonly &&
        !filterMeta.readonlyIcon &&
        filterMeta.source === PropertySource.Clerk;

    return (
        <Box
            sx={{
                width: 1,
                minHeight: 36,
                paddingX: 2,
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={4}
                sx={{
                    p: 2,
                    borderRadius: 1.5,
                    '&:hover': {
                        backgroundColor: 'custom.gray.superLight',
                    },
                }}
            >
                <Box
                    sx={{
                        marginTop: 1.5,
                        flexShrink: 0,
                        cursor: !canEditIcon ? 'default' : 'pointer',
                        color: 'custom.gray.super',

                        '&:hover': {
                            color: !canEditIcon ? undefined : 'info.main',
                        },
                    }}
                    component={Icon}
                    size="20px"
                    name={getPropertyIcon(
                        filterMeta.icon,
                        filterMeta.type,
                        filterMeta.source,
                    )}
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                        if (!canEditIcon) {
                            return;
                        }
                        setIconEditorActive(!iconEditorActive);
                        menu.handleOpen(e);
                    }}
                />

                <Tooltip title={filterMeta.name} placement="left">
                    <Typography
                        sx={{
                            width: 80,
                            flexShrink: 0,
                            cursor: filterMeta.readonly ? 'default' : 'pointer',
                        }}
                        variant="body3"
                        color="custom.gray.super"
                        title={filterMeta.name}
                        noWrap
                        onClick={(e) => {
                            if (filterMeta.readonly) {
                                return () => false;
                            }
                            setNameEditorActive(true);
                            menu.handleOpen(e);
                        }}
                    >
                        {filterMeta.name}
                    </Typography>
                </Tooltip>
            </Stack>

            {renderValue(
                userProperty,
                contactId,
                valueTransformer,
                onValueChange,
                onOptionCreate,
                isInbox,
            )}
            <Popover
                open={menu.open}
                anchorEl={menu.anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: nameEditorActive ? 44 : 'left',
                }}
                slotProps={{
                    paper: {
                        elevation: 5,
                        sx: {
                            borderRadius: 1.5,
                            margin: 0,
                            border: '1px solid',
                            borderColor: 'custom.gray.divider',
                            width: 240,
                        },
                    },
                }}
            >
                {iconEditorActive && (
                    <PropertyIconEditor
                        currentIcon={userProperty.icon}
                        onSelect={(icon) => {
                            onPropertyChange({ ...userProperty, icon });
                            handleClose();
                        }}
                    />
                )}
                {nameEditorActive &&
                    filterMeta.source === PropertySource.Clerk && (
                        <PropertyConfigurator
                            type={
                                (userProperty.type as ContactPropertyType) ??
                                ContactPropertyType.Text
                            }
                            name={userProperty.name}
                            onNameChange={(name) => {
                                onPropertyChange({ ...userProperty, name });
                                handleClose();
                            }}
                            onClose={handleDelete}
                        />
                    )}
                {nameEditorActive &&
                    filterMeta.source !== PropertySource.Clerk && (
                        <ExternalPropertyConfigurator
                            externalUpdateBehavior={
                                userProperty.externalUpdateBehavior
                            }
                            propertyName={userProperty.name}
                            onUpdateBehaviorChanged={(
                                externalUpdateBehavior,
                            ) => {
                                onPropertyChange({
                                    ...userProperty,
                                    externalUpdateBehavior,
                                });
                                handleClose();
                            }}
                            onClose={handleDelete}
                        />
                    )}
            </Popover>
        </Box>
    );
}

export default UserProperty;
