import { IconButton, Stack, Typography } from '@mui/material';
import { CsvIcon } from '../../../icons/common/files/ExtensionIcon';
import { CloseIcon } from '../../../icons/common/CCloseIcon';
import { useCsvImportContext } from './CsvImportContext';
import { colors } from '../../../theme/palette';

export const CsvFilePreview = () => {
    const { file, clearForm } = useCsvImportContext();

    if (!file) {
        return null;
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
                alignSelf: 'self-start',
                px: 2,
                py: 1.5,
                border: '1px solid',
                borderColor: colors.lightGrey[400],
                borderRadius: 1.5,
                maxWidth: 300,
                boxSizing: 'border-box',
            }}
        >
            <CsvIcon sx={{ height: 20 }} />
            <Typography variant="body4" color="primary.dark" noWrap>
                {file.name}
            </Typography>
            <IconButton
                size="small"
                onClick={() => {
                    clearForm();
                }}
            >
                <CloseIcon />
            </IconButton>
        </Stack>
    );
};
