import { useEffect, useState } from 'react';
import { ValueProps } from '../../types';
import CheckBox from '../../../../elements/CheckBox/CheckBox';
import { FormControlLabel } from '@mui/material';
import * as styles from '../styles';

export const BooleanValue = ({
    userProperty,
    onChange,
    readonly,
}: ValueProps<boolean>) => {
    const [value, setValue] = useState(!!userProperty.value);

    useEffect(() => {
        setValue(!!userProperty.value);
    }, [userProperty]);

    if (readonly) {
        return <CheckBox checked={value} size="normal" disabled />;
    }

    return (
        <FormControlLabel
            sx={[styles.value, { m: 0 }]}
            checked={value}
            onChange={(newValue) => {
                setValue(newValue);
                onChange?.({ ...userProperty, value: newValue });
            }}
            control={<CheckBox size="normal" />}
            label=""
        />
    );
};
