import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

export function Header({ title }: { title: React.ReactNode }) {
    return (
        <>
            <Box p={2}>
                {typeof title === 'string' ? (
                    <Typography
                        variant="body3"
                        color="primary.dark"
                        fontWeight={500}
                    >
                        {title}
                    </Typography>
                ) : (
                    title
                )}
            </Box>
            <Divider sx={{ my: 1 }} />
        </>
    );
}
