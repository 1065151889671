import { ContactPropertyType } from '../../../../api/types';
import { Box, Divider, TextField } from '@mui/material';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Actions } from './Actions';

type FormFields = {
    label: string;
};

const schema = Yup.object({
    label: Yup.string().required("Property name can't be empty"),
});

type PropertyConfiguratorProps = {
    type: ContactPropertyType;
    name?: string;
    onNameChange: (name: string) => void;
    onClose: () => void;
    isPending?: boolean;
    error?: string | null;
};

export const PropertyConfigurator = ({
    onClose,
    onNameChange,
    name,
    error,
    isPending = false,
}: PropertyConfiguratorProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormFields>({
        defaultValues: {
            label: name ?? '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = ({ label }: FormFields) => {
        onNameChange(label);
    };

    const errorMessage = error || errors.label?.message || null;

    return (
        <Box py={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box px={4} py={2}>
                    <TextField
                        {...register('label')}
                        fullWidth
                        autoFocus
                        size="small"
                        placeholder="Property name"
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                </Box>
                <Divider sx={{ mt: 1, mb: 0.75 }} />
                <Actions onRemove={onClose} isPending={isPending} />
            </form>
        </Box>
    );
};
