import { PaperProps, SxProps } from '@mui/material';

export const section: SxProps = {
    width: 1,
    pt: 3,
    pb: 2,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'divider',
};
export const dialogPaperProps: PaperProps = {
    elevation: 5,
    sx: {
        width: [1, 428, 450],
        minWidth: 320,
        maxWidth: 428,
        minHeight: 240,
        maxHeight: '80vh',
        pt: 4,
    },
};
export const menuList: SxProps = {
    width: 1,
    maxHeight: '60vh',
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 1,
    pb: 0,
};
export const container = {
    width: 1,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 1,
};
