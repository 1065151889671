import { useByPhones } from '../../Contacts/use-contact-by-phone';
import { useMemo } from 'react';
import {
    CURRENT_USER_KEY,
    useEnabledFeature,
    useMeQueryData,
} from '../../../queries/user';
import { formatPhoneNumber } from '../../../utils/phoneNumber';
import type { CountryCode } from 'libphonenumber-js/types';
import { getPlaceholder } from '../../Contacts/get-placeholder';
import { useQueryClient } from '@tanstack/react-query';
import { Contact, ContactPhone, Feature, Profile } from '../../../api/types';
import { getPrimaryPhone } from '../../NewConversation/utils';

export const useName = (phones: string[], defaultValue = 'Conversation') => {
    const country =
        useQueryClient().getQueryData<Profile>([CURRENT_USER_KEY])?.activeTeam
            .countryCode || 'US';
    const memberQueries = useByPhones(phones);
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return {
        isLoading: memberQueries.some(({ isLoading }) => isLoading),
        data:
            phones
                .map(
                    (phone) =>
                        memberQueries.find((query) =>
                            isMultipleContactPhonesEnabled
                                ? (query.data?.phones ?? []).some(
                                      (contactPhone: ContactPhone) =>
                                          contactPhone.phone === phone,
                                  )
                                : query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .map((contact: Partial<Contact>) => {
                    return (
                        contact.name ||
                        formatPhoneNumber(
                            (isMultipleContactPhonesEnabled
                                ? getPrimaryPhone(contact.phones ?? [])
                                : contact
                            )?.phone,
                            country,
                        )
                    );
                })
                .join(', ') || defaultValue,
    };
};

export const useConversationName = (
    phones: string[],
    defaultValue = 'Conversation',
) => {
    const countryCode: CountryCode =
        useMeQueryData()?.activeTeam.countryCode || 'US';
    const memberQueries = useByPhones(phones);
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useMemo<string>(
        () =>
            phones
                .map(
                    (phone) =>
                        memberQueries.find((query) =>
                            isMultipleContactPhonesEnabled
                                ? (query.data?.phones ?? []).some(
                                      (contactPhone: ContactPhone) =>
                                          contactPhone.phone === phone,
                                  )
                                : query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .map((contact: Partial<Contact>) => {
                    return (
                        contact.name ||
                        formatPhoneNumber(
                            (isMultipleContactPhonesEnabled
                                ? getPrimaryPhone(contact.phones ?? [])
                                : contact
                            )?.phone ?? '',
                            countryCode,
                        )
                    );
                })
                .join(', ') || defaultValue,
        [
            phones,
            defaultValue,
            memberQueries,
            isMultipleContactPhonesEnabled,
            countryCode,
        ],
    );
};

export const useConversationAvatar = (phones: string[]) => {
    const memberQueries = useByPhones(phones);
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useMemo<string | undefined>(
        () =>
            phones
                .map(
                    (phone) =>
                        memberQueries.find((query) =>
                            isMultipleContactPhonesEnabled
                                ? (query.data?.phones ?? []).some(
                                      (contactPhone: ContactPhone) =>
                                          contactPhone.phone === phone,
                                  )
                                : query.data?.phone === phone,
                        )?.data ?? getPlaceholder(phone),
                )
                .find(({ avatarURL }) => avatarURL)?.avatarURL,
        [isMultipleContactPhonesEnabled, memberQueries, phones],
    );
};
