import { Box, ButtonBase, MenuItem } from '@mui/material';
import { ButtonDotLoader } from '../../../Forms/ButtonDotLoader';

export function Actions({
    onRemove,
    isPending = false,
}: {
    onRemove: () => void;
    isPending?: boolean;
}) {
    return (
        <Box display="flex" flexDirection="column">
            <MenuItem
                component={ButtonBase}
                sx={{
                    flex: 1,
                    textAlign: 'left',
                }}
                type="submit"
                disabled={isPending}
            >
                {isPending ? <ButtonDotLoader /> : 'Save'}
            </MenuItem>
            <MenuItem onClick={onRemove} disabled={isPending}>
                Delete
            </MenuItem>
        </Box>
    );
}
