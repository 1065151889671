import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ConversationPage } from '../../main/campaign/v3/use-campaign-messages';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';
import { queryClient } from '../../queries/queryClient';
import { FindParams, getConversations } from '../../api/conversation';

export const CONVERSATION_SEARCH_KEY = 'conversations_search';

export function useConversationsSearch(filter: Omit<FindParams, 'page'>) {
    const prev = useRef(filter);
    useEffect(() => {
        if (!isEqual(prev.current, filter)) {
            queryClient.cancelQueries({
                fetchStatus: 'fetching',
                queryKey: [CONVERSATION_SEARCH_KEY, prev.current],
                exact: true,
            });
        }
        prev.current = filter;
    }, [filter]);

    return useInfiniteQuery<ConversationPage>({
        initialPageParam: 1,
        queryFn: ({ pageParam: page, signal }) =>
            getConversations(
                { ...filter, page: page as number | undefined },
                signal,
            ),
        queryKey: [CONVERSATION_SEARCH_KEY, filter],
        getNextPageParam: (last): number | null => {
            return last?.data.length < last.limit
                ? null
                : (last?.page || 0) + 1;
        },
        enabled: !!filter.query,
        gcTime: 30_000,
    });
}

const CONVERSATIONS_GLOBAL_SEARCH = 'conversations_global_search';

export const useConversationSearch = (query: string) => {
    const prev = useRef(query);
    useEffect(() => {
        if (!isEqual(prev.current, query)) {
            queryClient.cancelQueries({
                fetchStatus: 'fetching',
                queryKey: [CONVERSATIONS_GLOBAL_SEARCH, prev.current],
                exact: true,
            });
        }
        prev.current = query;
    }, [query]);

    return useQuery({
        queryKey: [CONVERSATIONS_GLOBAL_SEARCH, query],
        queryFn: ({ signal }) =>
            getConversations({ query: query.trim() || undefined }, signal).then(
                ({ data }) => data,
            ),
    });
};
