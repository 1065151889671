import { Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import { InboxSidebar } from '../../components/Inbox/InboxSidebar';
import {
    ArchiveState,
    Entity,
    isArchiveState,
    useGoToQueryParam,
} from './query.helpers';
import { CampaignHeader } from '../../main/campaign/v3/CampaignHeader';
import { UUID } from '../../types/uuid';
import { ConversationHeader } from './ConversationHeader';
import { ConversationView } from './Conversation/ConversationView';
import { CampaignView } from './CampaignView';
import { CampaignMessageView } from './CampaignMessageView';
import { ConversationContactPanel } from './ContactPanel/ConversationContactPanel';
import ConversationDiscussion from '../../components/ConversationDiscussion';
import { NewConversation } from '../../components/NewConversation/NewConversation';
import { SpecialConversationFilter } from '../../components/Inbox/InboxFilters';
import { QueryParam } from './query.params';
import { HEADER_HEIGHT, pixelize } from './inbox.styles';
import { useLocation, useNavigate } from 'react-router';
import { EmptyConversation } from './EmptyConversation';
import { useBetweenMdXl } from '../../hooks/useBetweenMdXl';
import { useIsBannerShown } from '../../main/settings/Compliance/useIsBannerShown';
import { BANNER_HEIGHT } from '../../components/Banner/Banner';
import { NavigationArea } from '../../hooks/use-keyboard-navigation';
import { focusVisible } from '../../theme/focusVisible';
import { useConversationName } from '../../components/Inbox/Conversation/use-conversation-props';
import { useConversation } from '../../queries/conversations';
import isString from 'lodash/isString';
import { InboxProvider } from '../../components/Inbox/InboxProvider/InboxProvider';

export type QueryParams = {
    [QueryParam.ArchiveState]?: ArchiveState;
    [QueryParam.ContactInfo]?: 'true' | 'false' | null;
    [QueryParam.SpecialFilter]?: SpecialConversationFilter;
    [QueryParam.DiscussionId]?: number;
    [QueryParam.CohortIds]?: string;
};

/*todo: possible improvement use entity as discrimination union*/
type Props = {
    inboxId: string;
    entityId?: UUID | number;
    subEntityId?: number;
    entity?: Entity;
    query?: QueryParams;
    hasContactInfo?: boolean;
};

export const ModernInbox = ({
    query = { [QueryParam.ArchiveState]: ArchiveState.Active },
    entity = Entity.Conversations,
    inboxId,
    entityId,
    subEntityId,
    hasContactInfo,
}: Props) => {
    const state = isArchiveState(query[QueryParam.ArchiveState])
        ? query[QueryParam.ArchiveState]
        : ArchiveState.Active;

    const goToQueryParam = useGoToQueryParam();

    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { data } = useConversation(
        entity,
        entity === Entity.Conversations && isString(entityId),
    );
    const name = useConversationName(data?.members ?? []);

    const isNewView = entity === Entity.New;
    const isCampaignView = !!(
        entity === Entity.Campaigns &&
        entityId &&
        typeof entityId === 'number' &&
        !subEntityId
    );
    const isConversationView = !!(
        entity === Entity.Conversations &&
        entityId &&
        typeof entityId === 'string'
    );

    const isCampaignMessageView = !!(
        entity === Entity.Campaigns &&
        entityId &&
        typeof entityId === 'number' &&
        subEntityId
    );
    const prevUrl = isCampaignMessageView
        ? pathname.split('/').slice(0, 5).join('/')
        : undefined;
    const isUpXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
    const isBetweenMdXl = useBetweenMdXl();
    const discussionId = query?.[QueryParam.DiscussionId];
    const isContactInfoShown =
        isConversationView &&
        hasContactInfo &&
        (isUpXl
            ? query?.[QueryParam.ContactInfo] !== 'false'
            : query?.[QueryParam.ContactInfo] === 'true') &&
        !discussionId;
    const isRightSidebar = !!(isContactInfoShown || discussionId);
    const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
    const isEmptyView =
        !isNewView &&
        !isConversationView &&
        !isCampaignView &&
        !isCampaignMessageView;
    const isBannerShown = useIsBannerShown();
    const topOffset = BANNER_HEIGHT + HEADER_HEIGHT;

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: [
                    'auto',
                    'auto',
                    isRightSidebar && !isBetweenMdXl
                        ? '300px auto 320px'
                        : '300px auto',
                ],
                width: 1,
                height: [
                    `calc(100% - ${pixelize(topOffset)})`,
                    `calc(100% - ${pixelize(topOffset)})`,
                    isBannerShown
                        ? `calc(100% - ${pixelize(BANNER_HEIGHT)})`
                        : 1,
                ],
            }}
        >
            <Box
                sx={{
                    display:
                        (isUpMd && 'block') ||
                        (!isUpMd && entity === Entity.New && 'none') ||
                        (entityId && 'none') ||
                        'block',
                }}
            >
                <InboxSidebar
                    inboxId={inboxId}
                    entity={entity}
                    specialFilter={query?.[QueryParam.SpecialFilter]}
                    state={state}
                    selectedCohortIds={
                        query?.[QueryParam.CohortIds]
                            ?.split(',')
                            .filter(Boolean)
                            .map(Number) || []
                    }
                />
            </Box>
            <InboxProvider inboxId={inboxId}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'minmax(0, 1fr)',
                        position: 'relative',
                        ':focus-visible': {
                            ...focusVisible,
                            outlineOffset: '-1px',
                        },
                    }}
                    tabIndex={0}
                    id="dropzone-parent"
                    data-navigation-area={NavigationArea.Chat}
                    aria-label={
                        entityId
                            ? `Chat area for ${name}`
                            : 'Select conversation or start new chat'
                    }
                >
                    {isEmptyView && (
                        <EmptyConversation
                            title="No messages"
                            description="Add a message and start a conversation"
                            inboxId={inboxId}
                        />
                    )}

                    {isNewView && (
                        <NewConversation
                            data-testid="new-conversation"
                            inboxId={inboxId}
                            onFinish={(entityId, view) =>
                                navigate(
                                    `/inbox/${inboxId}/${view}s/${entityId}`,
                                )
                            }
                        />
                    )}
                    {isConversationView && (
                        <ConversationHeader conversationId={entityId} />
                    )}
                    {(isCampaignView || isCampaignMessageView) && (
                        <CampaignHeader
                            prevUrl={prevUrl}
                            campaignId={entityId}
                        />
                    )}
                    {(isConversationView ||
                        isCampaignView ||
                        isCampaignMessageView) && (
                        <Box
                            sx={{
                                display: 'flex',
                                maxHeight: `calc(100vh - ${pixelize(HEADER_HEIGHT)})`,
                                overflow: 'hidden',
                            }}
                        >
                            {isConversationView && (
                                <ConversationView conversationId={entityId} />
                            )}
                            {isCampaignView && (
                                <CampaignView campaignId={entityId} />
                            )}
                            {isCampaignMessageView && (
                                <CampaignMessageView
                                    campaignMessageId={subEntityId}
                                />
                            )}
                        </Box>
                    )}
                    <Drawer
                        sx={{
                            width: [1, 320],
                            flexShrink: 0,
                            left: 'auto',
                            '& .MuiDrawer-paper': {
                                width: [1, 320],
                                boxSizing: 'border-box',
                            },
                        }}
                        hideBackdrop
                        variant="persistent"
                        anchor="right"
                        open={isRightSidebar}
                        PaperProps={{
                            elevation: isBetweenMdXl ? 5 : 0,
                            sx: {
                                height: `calc(100% - ${BANNER_HEIGHT}px)`,
                                marginTop: isBannerShown
                                    ? BANNER_HEIGHT / 4
                                    : 0,
                            },
                        }}
                    >
                        {isConversationView && isContactInfoShown && (
                            <ConversationContactPanel
                                conversationId={entityId}
                            />
                        )}
                        {isConversationView &&
                            !isContactInfoShown &&
                            discussionId && (
                                <ConversationDiscussion
                                    discussionId={+discussionId}
                                    onClose={() => {
                                        goToQueryParam({}, [
                                            QueryParam.DiscussionId,
                                        ]);
                                    }}
                                />
                            )}
                    </Drawer>
                </Box>
            </InboxProvider>
        </Box>
    );
};
