import { useMemo } from 'react';
import { UserTeamSettingType } from '../api/types';
import { useGetSetting, useMeQueryData } from '../queries/user';
import { UUID } from '../types/uuid';
import { sortInboxesByPredefinedOrder } from '../utils/inboxes';
import { useInboxes } from '../queries/inboxes';
import partition from 'lodash/partition';

const useSortedInboxes = () => {
    const { data: inboxes = [], isPending: isLoading } = useInboxes();
    const mutedInboxesIds = useMeQueryData()?.mutedInboxIds;
    const { data: inboxesOrderSetting, isPending: isSettingsLoading } =
        useGetSetting(UserTeamSettingType.InboxesOrder);

    return useMemo(() => {
        const order = (inboxesOrderSetting?.data?.ids || []) as UUID[];
        const all = sortInboxesByPredefinedOrder(inboxes, order);
        const [muted, unmuted] = partition(all, (inbox) =>
            (mutedInboxesIds || []).includes(inbox.id),
        );

        return {
            muted,
            unmuted,
            all,
            isPending: isLoading || isSettingsLoading,
        };
    }, [
        inboxes,
        inboxesOrderSetting?.data?.ids,
        isLoading,
        isSettingsLoading,
        mutedInboxesIds,
    ]);
};

export default useSortedInboxes;
