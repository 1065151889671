import { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';
import { RadioSelectedIcon } from '../icons/common/RadioSelectedIcon';
import { RadioEmptyIcon } from '../icons/common/RadioEmptyIcon';
import { getSquare } from './style.helpers';

export const MuiRadio: Components<Theme>['MuiRadio'] = {
    defaultProps: {
        checkedIcon: <RadioSelectedIcon />,
        icon: <RadioEmptyIcon />,
    },
    styleOverrides: {
        sizeSmall: {
            padding: 8,
            '& .MuiSvgIcon-root': getSquare(20),
        },
    },
};
