import { useCallback } from 'react';
import { useUpdateMeSettings } from '../queries/user';
import { UserTeamSettingType } from '../api/types';
import useSortedInboxes from './useSortedInboxes';

const useSaveInboxesOrder = (isMuted: boolean = false) => {
    const { mutate: updateSettings } = useUpdateMeSettings();
    const { unmuted, muted } = useSortedInboxes();

    return useCallback(
        (sourceIndex: number, destinationIndex: number) => {
            const newInboxesOrder = Array.from(isMuted ? muted : unmuted);

            const [reorderedItem] = newInboxesOrder.splice(sourceIndex, 1);
            newInboxesOrder.splice(destinationIndex, 0, reorderedItem);

            updateSettings({
                enabled: true,
                type: UserTeamSettingType.InboxesOrder,
                data: {
                    ids: (isMuted
                        ? unmuted.concat(newInboxesOrder)
                        : newInboxesOrder.concat(muted)
                    ).map((inbox) => inbox.id),
                },
            });
        },
        [isMuted, muted, unmuted, updateSettings],
    );
};

export default useSaveInboxesOrder;
