import { SvgIcon } from '@mui/material';
import { ClerkIconProps } from '../../../types/clerk-icon.props';

export const TextIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.875 3.33337C1.875 2.9882 2.15482 2.70837 2.5 2.70837H10.8333C11.1785 2.70837 11.4583 2.9882 11.4583 3.33337V5.83337C11.4583 6.17855 11.1785 6.45837 10.8333 6.45837C10.4882 6.45837 10.2083 6.17855 10.2083 5.83337V3.95837H7.29167V16.0417H8.33333C8.67851 16.0417 8.95833 16.3215 8.95833 16.6667C8.95833 17.0119 8.67851 17.2917 8.33333 17.2917H5C4.65482 17.2917 4.375 17.0119 4.375 16.6667C4.375 16.3215 4.65482 16.0417 5 16.0417H6.04167V3.95837H3.125V5.83337C3.125 6.17855 2.84518 6.45837 2.5 6.45837C2.15482 6.45837 1.875 6.17855 1.875 5.83337V3.33337ZM10.2083 10C10.2083 9.65486 10.4882 9.37504 10.8333 9.37504H17.5C17.8452 9.37504 18.125 9.65486 18.125 10V11.6667C18.125 12.0119 17.8452 12.2917 17.5 12.2917C17.1548 12.2917 16.875 12.0119 16.875 11.6667V10.625H14.7917V16.0417H15.8333C16.1785 16.0417 16.4583 16.3215 16.4583 16.6667C16.4583 17.0119 16.1785 17.2917 15.8333 17.2917H12.5C12.1548 17.2917 11.875 17.0119 11.875 16.6667C11.875 16.3215 12.1548 16.0417 12.5 16.0417H13.5417V10.625H11.4583V11.6667C11.4583 12.0119 11.1785 12.2917 10.8333 12.2917C10.4882 12.2917 10.2083 12.0119 10.2083 11.6667V10Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
