import { UserProfileIcon } from '../../../icons/common/UserProfileIcon';
import { getSquare } from '../../../theme/style.helpers';
import { ContactPropertyType, Feature } from '../../../api/types';
import { PropertySource } from '../../../components/ContactProperty/types';
import { ExtendedUserPropertyModel } from './PropertyRow';
import { useEnabledFeature } from '../../../queries/user';
import { useMemo } from 'react';

export const useDefaultFields = (): ExtendedUserPropertyModel[] => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    return useMemo(() => {
        const defaultFields: ExtendedUserPropertyModel[] = [
            {
                id: 'name',
                icon: (
                    <UserProfileIcon
                        sx={[{ color: 'custom.gray.super' }, getSquare(20)]}
                    />
                ),
                type: ContactPropertyType.Text,
                name: 'Name',
                value: '',
                source: PropertySource.Clerk,
                itemType: 'field',
            } as ExtendedUserPropertyModel,
            {
                id: 'email',
                type: ContactPropertyType.Email,
                name: 'Email',
                icon: 'email-2',
                value: '',
                source: PropertySource.Clerk,
                itemType: 'field',
            },
        ];

        if (isMultipleContactPhonesEnabled) {
            return defaultFields;
        }

        return defaultFields.concat({
            id: 'phone',
            type: ContactPropertyType.Phone,
            name: 'Phone',
            icon: 'phone',
            value: '',
            source: PropertySource.Clerk,
            itemType: 'field',
        });
    }, [isMultipleContactPhonesEnabled]);
};
