import { ClerkIconProps } from '../../../types/clerk-icon.props';
import { SvgIcon } from '@mui/material';

export const PrivateUserIcon = (props: ClerkIconProps) => (
    <SvgIcon inheritViewBox {...props}>
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.16671 3.125C7.67094 3.125 6.45837 4.33756 6.45837 5.83333C6.45837 7.3291 7.67094 8.54167 9.16671 8.54167C10.6625 8.54167 11.875 7.3291 11.875 5.83333C11.875 4.33756 10.6625 3.125 9.16671 3.125ZM5.20837 5.83333C5.20837 3.64721 6.98058 1.875 9.16671 1.875C11.3528 1.875 13.125 3.64721 13.125 5.83333C13.125 8.01946 11.3528 9.79167 9.16671 9.79167C6.98058 9.79167 5.20837 8.01946 5.20837 5.83333ZM9.16671 12.2917C6.29022 12.2917 3.95837 14.6235 3.95837 17.5C3.95837 17.8452 3.67855 18.125 3.33337 18.125C2.9882 18.125 2.70837 17.8452 2.70837 17.5C2.70837 13.9332 5.59987 11.0417 9.16671 11.0417C10.538 11.0417 11.8113 11.4698 12.8576 12.1996C13.1407 12.3971 13.2101 12.7867 13.0127 13.0698C12.8152 13.3529 12.4256 13.4223 12.1425 13.2249C11.2991 12.6366 10.2741 12.2917 9.16671 12.2917ZM17.1193 13.7356C17.3573 13.9856 17.3477 14.3812 17.0977 14.6193L13.5977 17.9526C13.3564 18.1825 12.9771 18.1825 12.7357 17.9526L11.2357 16.524C10.9857 16.286 10.9761 15.8904 11.2141 15.6404C11.4522 15.3904 11.8478 15.3808 12.0977 15.6188L13.1667 16.6369L16.2357 13.7141C16.4856 13.476 16.8812 13.4857 17.1193 13.7356Z"
                fill="currentColor"
            />
        </svg>
    </SvgIcon>
);
