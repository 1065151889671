import { Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { CohortAutocomplete } from '../../../components/Contacts/Cohorts/CohortAutocomplete';
import { useCsvImportContext } from './CsvImportContext';
import { CohortMeta } from '../../../api/types';
import { pluralize } from '../../../utils/pluralize';

type ListOption = 'create-list' | 'add-to-list' | 'no-list';

export const ListForm = () => {
    const {
        enabled,
        cohort,
        newCohortName,
        setCohort,
        clearCohort,
        setCohortName,
        setIsPrivate,
        numOfLines,
    } = useCsvImportContext();
    const [listOption, setListOption] = useState<ListOption>('no-list');

    if (!enabled) {
        return null;
    }

    return (
        <>
            <Divider />
            <Stack spacing={6}>
                <Typography
                    variant="body2"
                    color="primary.dark"
                    fontWeight={500}
                >
                    List creation
                </Typography>
                <Stack
                    spacing={2}
                    justifyContent="space-between"
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    direction={{ xs: 'column', sm: 'row' }}
                >
                    <Typography variant="body3" color="primary.dark">
                        What should we do with the uploaded{' '}
                        {pluralize(
                            numOfLines,
                            'contact',
                            'contacts',
                            'contacts',
                            '',
                        )}
                        ?
                    </Typography>

                    <TextField
                        select
                        sx={{
                            width: 264,
                        }}
                        value={listOption}
                        onChange={(event) => {
                            setListOption(event.target.value as ListOption);
                        }}
                    >
                        <MenuItem value="create-list">
                            Create a new List
                        </MenuItem>
                        <MenuItem value="add-to-list">
                            Add{' '}
                            {pluralize(
                                numOfLines,
                                'contact',
                                'contacts',
                                'contacts',
                                '',
                            )}{' '}
                            to an existing list
                        </MenuItem>
                        <MenuItem value="no-list">
                            Add as new{' '}
                            {pluralize(
                                numOfLines,
                                'contact',
                                'contacts',
                                'contacts',
                                '',
                            )}{' '}
                        </MenuItem>
                    </TextField>
                </Stack>

                {listOption === 'add-to-list' && (
                    <Stack
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <Typography variant="body3" color="primary.dark">
                            Select the existing list
                        </Typography>

                        <CohortAutocomplete
                            sx={{
                                width: 264,
                            }}
                            placeholder="Select existing list"
                            selected={cohort}
                            onChange={(cohort) => {
                                if (cohort) {
                                    setIsPrivate(!cohort.isPublic);
                                    setCohort(cohort);
                                } else {
                                    clearCohort();
                                }
                            }}
                            renderOption={(props, option: CohortMeta) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                    {!option.isPublic && (
                                        <Typography
                                            variant="body4"
                                            color="custom.gray.super"
                                            ml={1}
                                        >
                                            (Private)
                                        </Typography>
                                    )}
                                </li>
                            )}
                        />
                    </Stack>
                )}

                {listOption === 'create-list' && (
                    <Stack
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <Typography variant="body3" color="primary.dark">
                            Create a new list
                        </Typography>

                        <TextField
                            sx={{ width: 264 }}
                            placeholder="List name"
                            value={newCohortName}
                            onChange={(event) => {
                                setCohortName(event.target.value);
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </>
    );
};
