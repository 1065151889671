import Typography from '@mui/material/Typography';
import { Box, Breadcrumbs } from '@mui/material';
import * as styles from './styles';
import React from 'react';

type Props = {
    title: string;
    description?: React.ReactNode;
    category: string;
    action?: React.ReactNode | null;
    breadcrumbs?: React.ReactNode[];
};
export const ContentHeader = ({
    title,
    category,
    description,
    action,
    breadcrumbs,
}: Props) => {
    return (
        <Box
            sx={{
                ...styles.layoutContainer,
                borderBottom: '1px solid',
                borderColor: 'custom.gray.divider',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: { xs: 'baseline', md: 'center' },
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: 4, md: 2 },
                }}
            >
                <div>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        variant="body4"
                        sx={{ mb: 4 }}
                    >
                        {(breadcrumbs ?? [category || 'Settings']).map(
                            (crumb) =>
                                typeof crumb === 'string' ? (
                                    <Typography
                                        variant="body4"
                                        color="text.disabled"
                                        key={crumb}
                                    >
                                        {crumb}
                                    </Typography>
                                ) : (
                                    crumb
                                ),
                        )}
                        <Typography variant="body4" color="text.primary">
                            {title}
                        </Typography>
                    </Breadcrumbs>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <div>
                            <Typography variant="h1" fontWeight={600} mb={2}>
                                {title}
                            </Typography>
                            {description && (
                                <Typography
                                    color="custom.gray.super"
                                    variant="subtitle2"
                                >
                                    {description}
                                </Typography>
                            )}
                        </div>
                        {action}
                    </Box>
                </div>
            </Box>
        </Box>
    );
};
