import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import {
    getTermSelect,
    useMeQueryData,
    useTeammates,
} from '../../../../queries/user';
import { useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { UserRow } from './UserRow';
import { HideDownSm } from '../../../../components/MediaHelpers/HideDownSm';
import { ClerkPermission } from '../../../../api/types';
import { WithPermission } from '../../../../containers/WithPermission/WithPermission';

type Props = { searchTerm: string };

export const UserTable = ({ searchTerm }: Props) => {
    const me = useMeQueryData();
    const { data: users = [] } = useTeammates({
        select: searchTerm ? getTermSelect(searchTerm) : undefined,
    });

    const filteredUsers = useMemo(() => {
        const current = users.filter((u) => u.id === me?.id)[0];
        const withoutCurrentUser = users.filter((u) => u.id !== me?.id);

        return [
            current,
            ...sortBy(withoutCurrentUser, [
                'firstname',
                'lastname',
                'email',
                'phone',
            ]),
        ].filter(Boolean);
    }, [users, me?.id]);

    return (
        <Table aria-label="member table">
            <TableHead>
                <TableRow>
                    <TableCell>User</TableCell>
                    <WithPermission
                        requiredPermissions={[
                            ClerkPermission.ModifyPublicInbox,
                            ClerkPermission.ModifyPrivateInbox,
                        ]}
                    >
                        <HideDownSm>
                            <TableCell>Assigned phone(s)</TableCell>
                        </HideDownSm>
                    </WithPermission>
                    <TableCell>Access level</TableCell>
                    <TableCell width={20} />
                </TableRow>
            </TableHead>
            <TableBody>
                {filteredUsers.map((user) => (
                    <UserRow key={user.id} user={user} />
                ))}
            </TableBody>
        </Table>
    );
};
