import { Icon } from '../../icons/Icon';
import { useSendOTP } from '../../queries/auth';
import {
    Button,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { ButtonDotLoader } from './ButtonDotLoader';
import { stepForm } from './styles';
import { useTeams } from 'msteams-react-base-component';
import { useRef } from 'react';
import { WebexIcon } from '../../icons/logos/WebexIcon';
import { Link } from 'react-router-dom';
import { GoogleLogoIcon } from '../../icons/logos/GoogleLogoIcon';

interface EmailStepProps {
    email: string;
    setEmail: (email: string) => void;
    next: () => void;
    setMethodId: (methodId: string) => void;
    isSignUp: boolean;
}

const EmailStep = ({
    email,
    next,
    setEmail,
    setMethodId,
    isSignUp,
}: EmailStepProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [{ inTeams }] = useTeams();

    const { mutateAsync, isPending, isError, error } = useSendOTP();

    const errorMessage =
        error?.response?.data?.statusCode === 406
            ? 'Free email addresses are not supported. Please use a valid business domain.'
            : 'Please Contact Support at support@clerk.chat';

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!email) {
            inputRef.current?.focus();
        }
        mutateAsync(email)
            .then(({ email_id }) => setMethodId(email_id))
            .then(next)
            // eslint-disable-next-line no-console
            .catch(console.log);
    };

    return (
        <Stack gap={6} component="form" onSubmit={handleSubmit} sx={stepForm}>
            <TextField
                name="token"
                error={isError}
                value={email}
                onChange={(event) => setEmail(event.target.value ?? '')}
                InputProps={{
                    'aria-label': 'Email',
                    ref: inputRef,
                    placeholder: 'Email address',
                    type: 'email',
                    startAdornment: (
                        <InputAdornment position="start">
                            <Icon name="email-2" />
                        </InputAdornment>
                    ),
                }}
                helperText={isError ? errorMessage : ''}
                fullWidth
                autoFocus
            />

            {!inTeams && (
                <>
                    <Button
                        fullWidth
                        disabled={isPending || !email}
                        type="submit"
                        color="primary"
                        size="large"
                    >
                        {isPending && <ButtonDotLoader />}
                        {!isPending && (isSignUp ? 'Sign Up' : 'Login')}
                    </Button>

                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<Icon name="microsoft-logo" />}
                        color="primary"
                        size="large"
                        href={import.meta.env.VITE_MS_OAUTH_URL}
                    >
                        Continue with Microsoft
                    </Button>
                    {!!import.meta.env.VITE_GOOGLE_OAUTH_URL && (
                        <Button
                            fullWidth
                            variant="outlined"
                            startIcon={<GoogleLogoIcon />}
                            color="primary"
                            size="large"
                            href={import.meta.env.VITE_GOOGLE_OAUTH_URL}
                        >
                            Continue with Google
                        </Button>
                    )}
                    <Button
                        fullWidth
                        variant="outlined"
                        startIcon={<WebexIcon />}
                        color="primary"
                        size="large"
                        href={import.meta.env.VITE_WEBEX_OAUTH_URL}
                    >
                        Continue with Webex
                    </Button>
                </>
            )}
            <Typography variant="body4" color="custom.gray.super">
                {isSignUp
                    ? 'Already have an account?'
                    : 'Don’t have an account?'}{' '}
                {isSignUp ? (
                    <Link to="/login">
                        <Typography variant="body4" color="primary.main">
                            Login
                        </Typography>
                    </Link>
                ) : (
                    <Link to="/signup" id="free_trial">
                        <Typography variant="body4" color="primary.main">
                            Sign Up
                        </Typography>
                    </Link>
                )}
            </Typography>
        </Stack>
    );
};

export default EmailStep;
