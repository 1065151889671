import { useMeQueryData } from '../../queries/user';
import { Alert, Box, Button, Stack } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle/AlertTitle';
import { Link as RouterLink } from 'react-router-dom';
import { useTrack } from '../../contexts/analytics';
import { RoleTypes } from '../../api/types';
import { useIsOutOfMessages } from '../../main/settings/Compliance/useIsOutOfMessages';

export const OutOfMessagesBanner = () => {
    const track = useTrack();
    const me = useMeQueryData();
    const navUrl = me?.activeTeam.stripeSubscriptionId
        ? '/settings/billing'
        : '/payment/plan';
    const isOutOfMessages = useIsOutOfMessages();
    const isWorkspaceOwner =
        (useMeQueryData()?.activeRole?.type || RoleTypes.Member) ===
        RoleTypes.WorkspaceOwner;

    if (!isOutOfMessages) {
        return null;
    }

    return (
        <Box sx={{ p: 6 }}>
            <Alert severity="warning" icon="😔">
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        width: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack sx={{ color: 'text.primary' }}>
                        <AlertTitle>You are out of messages</AlertTitle>
                        To continue sending messages, please upgrade your
                        account.
                    </Stack>
                    {isWorkspaceOwner && (
                        <Button
                            onClick={() =>
                                track('out_of_messages_banner_cta_clicked')
                            }
                            to={navUrl}
                            size="small"
                            variant="outlined"
                            component={RouterLink}
                        >
                            Upgrade
                        </Button>
                    )}
                </Box>
            </Alert>
        </Box>
    );
};
