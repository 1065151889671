import { Suspense, lazy, useState, useRef, useMemo, useCallback } from 'react';
import { Box, InputAdornment, Stack, TextField } from '@mui/material';
import { SearchFilledIcon } from '../../icons/common/SearchFilledIcon';
import { useLocalStorage } from '@uidotdev/usehooks';
import { emojiPickerTabs, ALL_GROUP, appsEmoji } from './constants';
import EmojiGroupList from './EmojiGroupList';
import { EmojiWithImages } from './types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import {
    clerkEmojiPicker,
    listWrapper,
    section,
    sectionTitle,
    tabs,
} from './styles';
import { Tab } from './Tab';

const EmojiList = lazy(() => import('./EmojiList'));

type EmojiWithCount = {
    emoji: EmojiWithImages;
    count: number;
};

type EmojiPickerProps = {
    onClick: (unicode: string) => void;
    withImages?: boolean;
    onClose?: () => void;
};

const EmojiPicker = ({
    onClick,
    withImages = false,
    onClose = () => {},
}: EmojiPickerProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState(ALL_GROUP);
    const [searchValue, setSearchValue] = useState('');
    const [frequentlyUsedCount, setFrequentlyUsedCount] = useLocalStorage<
        EmojiWithCount[]
    >('frequentlyUsedEmoji', []);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(wrapperRef, onClose);

    const onTabClick = useCallback((index: number) => {
        setActiveTabIndex(index);
    }, []);

    const handleClick = useCallback((emoji: EmojiWithImages) => {
        saveFrequentlyUsed(emoji);
        if (emoji.unicode) {
            onClick(emoji.unicode);
        }
        if (emoji.imageName) {
            onClick(emoji.imageName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveFrequentlyUsed = (emoji: EmojiWithImages) => {
        const updatedList = [
            ...frequentlyUsedCount.filter(
                (item) => item.emoji.hexcode !== emoji.hexcode,
            ),
            {
                emoji,
                count:
                    (frequentlyUsedCount.find(
                        (item) => item.emoji.hexcode === emoji.hexcode,
                    )?.count || 0) + 1,
            },
        ]
            .sort((a, b) => b.count - a.count)
            .slice(0, 10);

        setFrequentlyUsedCount(updatedList);
    };

    const frequentlyUsed = useMemo(
        () =>
            frequentlyUsedCount
                .map((item) => item.emoji)
                .filter((item) => withImages || !item.imageName),
        [frequentlyUsedCount, withImages],
    );

    const filteredFeaturedEmoji = useMemo(
        () =>
            frequentlyUsed.filter(
                (emoji) =>
                    emoji.label
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    emoji.tags?.some((tag) =>
                        tag.includes(searchValue.toLowerCase()),
                    ),
            ),
        [frequentlyUsed, searchValue],
    );

    const filteredAppsEmoji = useMemo(
        () =>
            appsEmoji.filter(
                (emoji) =>
                    emoji.label
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    emoji.tags?.some((tag) =>
                        tag.includes(searchValue.toLowerCase()),
                    ),
            ),
        [searchValue],
    );

    return (
        <Box sx={clerkEmojiPicker} ref={wrapperRef}>
            <Box sx={tabs}>
                {emojiPickerTabs.map((emoji) => (
                    <Tab
                        activeTabIndex={activeTabIndex}
                        emoji={emoji}
                        onClick={onTabClick}
                        key={emoji.groupKey}
                    />
                ))}
            </Box>
            <Stack sx={listWrapper}>
                <TextField
                    size="small"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search emojis"
                    variant="outlined"
                    sx={{ width: '100%' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                sx={{
                                    '&.MuiInputAdornment-root': {
                                        m: 0,
                                    },
                                }}
                            >
                                <SearchFilledIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {activeTabIndex === ALL_GROUP ? (
                    <Box sx={section}>
                        {frequentlyUsed.length > 3 &&
                            filteredFeaturedEmoji.length > 0 && (
                                <Box>
                                    <Box sx={sectionTitle}>Frequently Used</Box>
                                    <Suspense fallback={<div />}>
                                        <EmojiList
                                            title="Frequently Used"
                                            emojiList={filteredFeaturedEmoji}
                                            onSelect={handleClick}
                                        />
                                    </Suspense>
                                </Box>
                            )}
                        {withImages && filteredAppsEmoji.length > 0 && (
                            <Box>
                                <Box sx={sectionTitle}>Apps</Box>
                                <Suspense fallback={<div />}>
                                    <EmojiList
                                        title="Apps"
                                        emojiList={filteredAppsEmoji}
                                        onSelect={handleClick}
                                    />
                                </Suspense>
                            </Box>
                        )}

                        {emojiPickerTabs.map((_, index) => (
                            <EmojiGroupList
                                key={index}
                                tabIndex={index}
                                emojiPickerTabs={emojiPickerTabs}
                                searchValue={searchValue}
                                onSelect={handleClick}
                            />
                        ))}
                    </Box>
                ) : (
                    <Box sx={section}>
                        <EmojiGroupList
                            tabIndex={activeTabIndex}
                            emojiPickerTabs={emojiPickerTabs}
                            searchValue={searchValue}
                            onSelect={handleClick}
                        />
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default EmojiPicker;
