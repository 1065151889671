import {
    Box,
    IconButton,
    InputAdornment,
    Skeleton,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { UUID } from '../../types/uuid';
import { useInbox } from '../../queries/inboxes';
import { renderInboxIcon, renderInboxName } from '../../utils/inboxes';
import { useTrack } from '../../contexts/analytics';
import {
    ArchiveState,
    Entity,
    EXTRA_OFFSET_ON_MOBILE,
} from '../../pages/NewInbox/query.helpers';
import { AdapterLink } from '../ConversationsList/AdapterLink';
import { InboxConversationsList } from './InboxConversationsList';
import { InboxFilters, SpecialConversationFilter } from './InboxFilters';
import { SelectedCohortList } from './SelectedCohortList';
import {
    ConversationsFilter,
    ConversationsFilterType,
    Feature,
} from '../../api/types';
import isEmpty from 'lodash/isEmpty';
import { CONVERSATIONS_DEFAULT_FILTER } from '../../api/conversation';
import { InboxCampaignList } from '../ConversationsList/InboxCampaignsList';
import { SearchFilledIcon } from '../../icons/common/SearchFilledIcon';
import { StateTabs } from './StateTabs';
import { useEffect, useState } from 'react';
import { header, inboxIcon, topContent } from './styles';
import { WithFeature } from '../../containers/Feature/WithFeature';
import { WithoutFeature } from '../../containers/Feature/WithoutFeature';
import { EntitySwitcher } from './EntitySwitcher';
import { useDebounce } from '@uidotdev/usehooks';
import { useSearchParams } from 'react-router-dom';
import { QueryParam } from '../../pages/NewInbox/query.params';
import { TruncatedTypography } from '../TruncatedText/TruncatedText';
import { ContextMenu } from './ContextMenu';
import { useIsBannerShown } from '../../main/settings/Compliance/useIsBannerShown';
import { BANNER_HEIGHT } from '../Banner/Banner';
import { useIsDownMd } from '../../hooks/useIsDownMd';
import { NavigationArea } from '../../hooks/use-keyboard-navigation';
import { focusVisible } from '../../theme/focusVisible';
import { AddFilledIcon } from '../../icons/common/AddFilledIcon';

type Props = {
    inboxId: UUID;
    state?: ArchiveState;
    entity?: Entity;
    selectedCohortIds?: number[];
    specialFilter?: SpecialConversationFilter;
};

const getFilter = ({
    selectedCohortIds,
    state,
    specialFilter,
}: {
    state?: ArchiveState;
    selectedCohortIds?: number[];
    specialFilter?: SpecialConversationFilter;
}): ConversationsFilter => {
    if (selectedCohortIds && !isEmpty(selectedCohortIds)) {
        return {
            type: ConversationsFilterType.ByCohorts,
            cohortsList: selectedCohortIds.map((id) => ({ id })),
        };
    }
    if (specialFilter === 'me') {
        return { type: ConversationsFilterType.AssignedToMe };
    }
    if (specialFilter === 'unread') {
        return { type: ConversationsFilterType.Unread };
    }
    if (state === ArchiveState.Archived) {
        return { type: ConversationsFilterType.Archived };
    }

    return CONVERSATIONS_DEFAULT_FILTER;
};

export const InboxSidebar = ({
    inboxId,
    state = ArchiveState.Active,
    entity = Entity.Conversations,
    selectedCohortIds = [],
    specialFilter,
}: Props) => {
    const [_, setParams] = useSearchParams();
    const inbox = useInbox(inboxId);
    const track = useTrack();
    const [query, setQuery] = useState<string>('');
    const debounced = useDebounce(query, 1500);
    const filter = getFilter({
        state,
        selectedCohortIds,
        specialFilter,
    });

    useEffect(() => {
        if (query.trim()) {
            track('inbox_search_query_used', {
                queryLength: debounced.length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounced]);

    useEffect(() => {
        if (state !== ArchiveState.Active) {
            setParams((prev) => {
                prev.delete(QueryParam.ArchiveState);
                return prev;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inboxId]);

    useEffect(() => {
        if (
            ![
                ConversationsFilterType.Active,
                ConversationsFilterType.Archived,
            ].includes(filter.type)
        ) {
            setQuery('');
        }
    }, [filter.type]);
    const isBannerShown = useIsBannerShown();
    const isMobile = useIsDownMd();
    const topOffset =
        (isMobile ? EXTRA_OFFSET_ON_MOBILE : 0) +
        (isBannerShown ? BANNER_HEIGHT : 0);

    return (
        <Box
            sx={{
                ...header,
                height: `calc(100vh - ${topOffset}px)`,
                '&:focus-visible': {
                    ...focusVisible,
                    outlineOffset: '-1px',
                },
            }}
            data-navigation-area={NavigationArea.Inbox}
            aria-label="Inbox sidebar"
            tabIndex={0}
        >
            <Box sx={topContent}>
                <Stack
                    direction="row"
                    gap={2}
                    width={1}
                    overflow="hidden"
                    alignItems="center"
                >
                    {inbox.isPending ? (
                        <Skeleton width={24} height={24} variant="circular" />
                    ) : (
                        <Box sx={inboxIcon} tabIndex={-1}>
                            {renderInboxIcon({
                                icon: inbox.data?.icon,
                                provider: inbox.data?.provider,
                            })}
                        </Box>
                    )}
                    <Box
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {inbox.isPending || !inbox.data ? (
                            <Skeleton width={80} />
                        ) : (
                            <TruncatedTypography
                                lines={1}
                                variant="body2"
                                fontWeight={500}
                                color="primary.dark"
                                tabIndex={-1}
                            >
                                {renderInboxName(inbox.data)}
                            </TruncatedTypography>
                        )}
                    </Box>
                </Stack>
                <Stack direction="row" gap={1}>
                    <ContextMenu inboxId={inboxId} disabled={inbox.isPending} />
                    <Tooltip title="New chat">
                        <IconButton
                            LinkComponent={AdapterLink}
                            href={`/inbox/${inboxId}/new`}
                            color="info"
                            sx={{
                                '&:focus-visible': {
                                    ...focusVisible,
                                    outlineOffset: '2px',
                                },
                            }}
                            onClick={() => {
                                track('new_chat_clicked', { inboxId });
                            }}
                            data-navigation-element
                            tabIndex={0}
                        >
                            <AddFilledIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
            <Box>
                <StateTabs state={state} />

                <Stack direction="row" m={4} gap={3}>
                    <WithFeature feature={Feature.InboxSearch}>
                        <TextField
                            value={query}
                            disabled={
                                ![Entity.Conversations, Entity.New].includes(
                                    entity,
                                )
                            }
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setQuery(e.target?.value || '');
                            }}
                            size="small"
                            placeholder="Search"
                            aria-label="Search"
                            InputProps={{
                                inputProps: {
                                    tabIndex: 0,
                                    ['data-navigation-element']: ![
                                        Entity.Conversations,
                                        Entity.New,
                                    ].includes(entity)
                                        ? undefined
                                        : true,
                                    autoComplete: 'off',
                                },
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        sx={{
                                            '&.MuiInputAdornment-root': {
                                                m: 0,
                                            },
                                        }}
                                    >
                                        <SearchFilledIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    </WithFeature>
                    <WithoutFeature feature={Feature.InboxSearch}>
                        <EntitySwitcher
                            inboxId={inboxId}
                            entity={entity}
                            sx={{ m: 0 }}
                        />
                    </WithoutFeature>
                    <InboxFilters
                        disabled={
                            ![Entity.Conversations, Entity.New].includes(entity)
                        }
                        defaultSelectedCohorts={selectedCohortIds}
                        defaultFilter={specialFilter}
                    />
                </Stack>
                <WithFeature feature={Feature.InboxSearch}>
                    <WithFeature feature={Feature.Campaigns}>
                        <EntitySwitcher inboxId={inboxId} entity={entity} />
                    </WithFeature>
                </WithFeature>
                <SelectedCohortList
                    selectedCohortIds={selectedCohortIds}
                    sx={{ pl: 5, pr: 2, py: 1 }}
                />
            </Box>
            <Box
                overflow="hidden"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 0,
                    flexGrow: 1,
                    height: 1,
                    px: 2,
                    alignSelf: 'stretch',
                }}
            >
                {entity === Entity.Campaigns && (
                    <InboxCampaignList inboxId={inboxId} />
                )}
                {[Entity.Conversations, Entity.New].includes(entity) && (
                    <InboxConversationsList
                        filter={filter}
                        term={query.trim()}
                        inboxId={inboxId}
                    />
                )}
            </Box>
        </Box>
    );
};
