import { useCallback, useMemo, useState } from 'react';
import { getMessageDraft, saveMessageDraft } from './utils';
import { Attachment, Feature, PublicFile } from '../../api/types';
import { useSocket } from '../../websocket';
import throttle from 'lodash/throttle';
import {
    EditorData,
    MessageInput,
    MessageInputProps,
} from '../MessageInput/MessageInput';
import { withReact } from 'slate-react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import { withTemplates } from '../MessageInput/components/Toolbar/Template/withTemplates';
import { Box } from '@mui/material';
import { withParagraphs } from '../MessageInput/components/Paragraph/withParagraph';
import { UUID } from '../../types/uuid';
import { useEnabledFeature } from '../../queries/user';

export type MessageFormData = {
    message: string;
    sendAt?: string;
    // @deprecated - use attachments
    files?: PublicFile[];
    attachments?: Attachment[];
    messageTemplateId?: number;
};

export type MessageFormProps = {
    signatureAvailable?: boolean;
    templatesAvailable?: boolean;
    initial?: MessageFormData | null;
    conversationId?: UUID;
    placeholder?: string;
    disabled?: boolean;
    onSend: (data: MessageFormData) => void;
    onCancel?: () => void;
    isChatFlowComponent?: boolean;
};

export function MessageForm({
    initial,
    conversationId,
    placeholder,
    disabled,
    onSend,
    onCancel,
    templatesAvailable,
    signatureAvailable,
    isChatFlowComponent = false,
}: MessageFormProps) {
    const aiEnabled = useEnabledFeature(Feature.AiWorkflow);
    const [editor] = useState(
        withTemplates(withParagraphs(withReact(withHistory(createEditor())))),
    );

    const initialValue = useMemo<string | Descendant[]>(() => {
        if (initial?.message) {
            return initial.message;
        }

        if (conversationId) {
            return getMessageDraft(conversationId);
        }

        return '';
    }, [conversationId, initial?.message]);

    const socket = useSocket();

    const saveDraft = (message?: Descendant[]) => {
        if (conversationId && !initial?.message) {
            saveMessageDraft({
                conversationId,
                message,
            });
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const emitTyping = useCallback(
        throttle(
            () => {
                if (conversationId) {
                    socket.getSocket()?.emit('conversation/typing', {
                        id: conversationId,
                    });
                }
            },
            5000,
            { leading: true, trailing: false },
        ),
        [conversationId],
    );

    const toolbar = useMemo(() => {
        const toolbarItems: MessageInputProps['toolbar'] = [
            'emojiPicker',
            'snippets',
            'schedule',
        ];

        if (signatureAvailable) {
            toolbarItems.push('signature');
        }

        if (templatesAvailable) {
            toolbarItems.push('templates');
        }

        return toolbarItems;
    }, [signatureAvailable, templatesAvailable]);

    const handleSend = async (data: EditorData) => {
        if (data.sendAt) {
            // await for server response for scheduled messages
            await onSend(data);
        } else {
            onSend(data);
        }
        saveDraft();
    };

    return (
        <Box
            sx={{
                position: 'relative',
                mb: isChatFlowComponent ? 0 : 6,
                mx: isChatFlowComponent ? 0 : 6,
            }}
            data-testid="message-input"
        >
            {editor && (
                <MessageInput
                    autoFocus
                    editor={editor}
                    placeholder={placeholder || 'Write a message...'}
                    initialValue={initialValue}
                    initialFiles={initial?.files}
                    initialAttachments={initial?.attachments ?? []}
                    sendAt={initial?.sendAt}
                    onChange={emitTyping}
                    onBlur={saveDraft}
                    onSend={handleSend}
                    onCancel={onCancel}
                    disabled={disabled}
                    withAiAssistant={aiEnabled}
                    conversationId={conversationId}
                    withFileUploader
                    withSendButton
                    edit={!!initial}
                    toolbar={toolbar}
                    isChatFlowComponent={isChatFlowComponent}
                />
            )}
        </Box>
    );
}
