import { Divider, List, ListSubheader, Stack } from '@mui/material';
import { ClerkPermission, Feature } from '../../../api/types';
import { WithPermission } from '../../WithPermission/WithPermission';
import { useEnabledFeature, useMeQueryData } from '../../../queries/user';
import { BrandRegistrationIcon } from './icons/BrandRegistrationIcon';
import { UserIcon } from './icons/UserIcon';
import { IntegrationsIcon } from './icons/IntegrationsIcon';
import { LogsIcon } from './icons/LogsIcon';
import { BlacklistIcon } from './icons/BlacklistIcon';
import { ApiKeyIcon } from './icons/ApiKeyIcon';
import { KnowledgeBaseIcon } from './icons/KnowledgeBaseIcon';
import NavigationItem from '../../../elements/Navigation';
import { WidgetIcon } from './icons/WidgetIcon';
import { WithFeature } from '../../Feature/WithFeature';
import { NotificationsIcon } from './icons/NotificationsIcon';
import { Members } from '../../../icons/common/CMembers';
import { CompanyIcon } from '../../../icons/common/CCompanyIcon';
import { CompanyRegistrationTooltip } from '../../../components/CompanyRegistrationTooltip/CompanyRegistrationTooltip';
import { useIsCampaignRegistered } from '../../../hooks/useIsCampaignRegistered';
import { useTrack } from '../../../contexts/analytics';
import { useSidebar } from '../../../contexts/sidebar';
import * as styles from '../styles';
import { WalletIcon } from './icons/WalletIcon';

export const Navigation = () => {
    const me = useMeQueryData();
    const enabled = useEnabledFeature(Feature.BrandOptInIntegration);
    const isCampaignRegistered = useIsCampaignRegistered();
    const track = useTrack();
    const { isCollapsed, toggleOpen } = useSidebar();

    return (
        <Stack
            component="nav"
            sx={styles.nav}
            divider={<Divider />}
            gap={4}
            pt={4}
        >
            <List
                subheader={
                    !isCollapsed && <ListSubheader>Account</ListSubheader>
                }
            >
                <NavigationItem
                    to="/settings/profile"
                    label="Profile"
                    icon={<UserIcon />}
                    onClick={toggleOpen}
                />
            </List>

            <List
                subheader={
                    !isCollapsed && <ListSubheader>Workspace</ListSubheader>
                }
            >
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <NavigationItem
                        to="/settings/team"
                        label="Team info"
                        icon={<CompanyIcon />}
                        onClick={toggleOpen}
                    />
                </WithPermission>
                <WithPermission
                    requiredPermissions={[
                        enabled
                            ? ClerkPermission.BrandAccess
                            : ClerkPermission.ModifyTeam,
                    ]}
                >
                    <NavigationItem
                        to="/settings/compliance"
                        label="Company Registration"
                        icon={<BrandRegistrationIcon />}
                        onClick={() => {
                            track('company_registration_clicked');
                            toggleOpen();
                        }}
                    >
                        {!isCampaignRegistered && (
                            <CompanyRegistrationTooltip />
                        )}
                    </NavigationItem>
                </WithPermission>
                <NavigationItem
                    to="/settings/members"
                    label="Members"
                    icon={<Members />}
                    onClick={toggleOpen}
                />
                <WithPermission
                    requiredPermissions={
                        me?.activeTeam.integrationsHiddenByDefault
                            ? [ClerkPermission.ModifyTeam]
                            : [ClerkPermission.ViewPublicInbox]
                    }
                >
                    <NavigationItem
                        to="/settings/integrations"
                        label="Integrations"
                        icon={<IntegrationsIcon />}
                        onClick={toggleOpen}
                    />
                </WithPermission>
                <WithFeature feature={Feature.ManageBilling}>
                    {(!me?.activeTeam.billingProvider ||
                        me?.activeTeam.billingProvider === 'stripe') && (
                        <NavigationItem
                            to="/settings/billing"
                            label="Billing"
                            icon={<WalletIcon />}
                        />
                    )}
                </WithFeature>
                <WithPermission
                    requiredPermissions={[ClerkPermission.ModifyTeam]}
                >
                    <WithFeature feature={Feature.AiWorkflow}>
                        <NavigationItem
                            to="/settings/knowledge-base"
                            label="Knowledge Base"
                            icon={<KnowledgeBaseIcon />}
                            onClick={toggleOpen}
                        />
                    </WithFeature>
                </WithPermission>
                <NavigationItem
                    onClick={toggleOpen}
                    to="/settings/notifications"
                    label="Notifications"
                    icon={<NotificationsIcon />}
                />
            </List>

            <WithPermission requiredPermissions={[ClerkPermission.ModifyTeam]}>
                <List
                    subheader={
                        !isCollapsed && <ListSubheader>Data</ListSubheader>
                    }
                >
                    <NavigationItem
                        to="/settings/export"
                        label="Logs"
                        icon={<LogsIcon />}
                        onClick={toggleOpen}
                    />
                    <NavigationItem
                        to="/settings/profanity"
                        label="Blacklist"
                        icon={<BlacklistIcon />}
                        onClick={toggleOpen}
                    />
                    <NavigationItem
                        to="/settings/api"
                        label="API"
                        icon={<ApiKeyIcon />}
                        onClick={toggleOpen}
                    />
                    <NavigationItem
                        to="/settings/widget"
                        label="Clerk Widget"
                        icon={<WidgetIcon />}
                        onClick={toggleOpen}
                    />
                </List>
            </WithPermission>
        </Stack>
    );
};
