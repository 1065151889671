import { SxProps } from '@mui/material';
import { colors } from '../../theme/palette';

export const inboxItemPaper: SxProps = { borderRadius: 2 };

export const list: SxProps = {
    listStyle: 'none',
};

export const inboxItem: SxProps = {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 1,
    mx: 2,
    position: 'relative',

    '&:first-of-type': {
        marginTop: 1,
    },

    '&:focus, &:active': {
        outline: 'none',
        boxShadow: 'none',
    },

    '&.active': {
        backgroundColor: 'custom.gray.actionSelected',
    },

    '&:hover': {
        backgroundColor: colors.lightGrey[100],
    },
};

export const inboxWithMenu: SxProps = {
    '&:hover': {
        '& > .context-menu-button': {
            transition: 'opacity .3s ease-in-out',
            opacity: 1,
        },
        '& > .indicators': {
            display: 'none',
        },
    },

    '& > .context-menu-button:focus-within': {
        transition: 'opacity .3s ease-in-out',
        opacity: 1,
        '& + .indicators': {
            display: 'none',
        },
    },
};

export const draggingItem: SxProps = {
    ...inboxItem,
    border: '1px solid',
    borderColor: 'info.main',
    backgroundColor: colors.lightGrey[50],
};

export const emojiIcon = { fontSize: 20, ml: 2, mr: 2 };

export const contextMenuWrapper = {
    opacity: 1,
    position: 'absolute',
    right: 8,
};

export const contextMenuWrapperHidden: SxProps = {
    ...contextMenuWrapper,
    opacity: 0,
};

export const indicatorsHidden: SxProps = {
    display: 'none',
};

export const statusIcon: SxProps = {
    cursor: 'pointer',
};
