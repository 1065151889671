import { Collapse, Stack, Typography } from '@mui/material';
import { useCsvImportContext } from './CsvImportContext';
import { CloseFilledIcon } from '../../../icons/common/CloseFilledIcon';
import { getSquare } from '../../../theme/style.helpers';

export const CsvError = () => {
    const { error } = useCsvImportContext();

    return (
        <Collapse in={!!error}>
            <Stack direction="row" spacing={2} alignItems="center">
                <CloseFilledIcon
                    sx={[
                        getSquare(20),
                        {
                            color: 'error.main',
                        },
                    ]}
                />
                <Typography variant="body4" color="error.main">
                    {error}
                </Typography>
            </Stack>
        </Collapse>
    );
};
