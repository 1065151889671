import { AnalyticsBrowser } from '@segment/analytics-next';
import { Profile } from '../api/types';
import { EventAction } from '../contexts/analytics';
import { useTeams } from 'msteams-react-base-component';
import { useEffect } from 'react';

export enum Channel {
    Web = 'web',
    Zoom = 'zoom',
    MsTeams = 'ms_teams',
    Webex = 'webex',
}

const STORAGE_KEY = 'analytics_channel';
export const setChannel = (channel: Channel) =>
    window.localStorage.setItem(STORAGE_KEY, channel);
export const getChannel = (): Channel =>
    (window.localStorage.getItem(STORAGE_KEY) as Channel) || Channel.Web;

export const analytics = AnalyticsBrowser.load(
    {
        writeKey: import.meta.env.VITE_SEGMENT_KEY,
    },
    {
        disable:
            !import.meta.env.VITE_SEGMENT_KEY ||
            import.meta.env.VITE_ANALYTICS_ENABLED !== 'true',
    },
);

analytics.addSourceMiddleware((ctx) => {
    const context = ctx.payload.obj.context;
    if (context) {
        context.channel = getChannel();
    }
    ctx.next(ctx.payload);
});

export function trackPage(pageName: string) {
    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
        analytics.page(pageName);
    } else {
        // eslint-disable-next-line no-console
        console.log('Tracking Disabled', pageName);
    }
}

export function trackEvent(event: EventAction) {
    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
        analytics.track(event.eventName, event.eventPayload);
    } else {
        // eslint-disable-next-line no-console
        console.log('Tracking Disabled', event.eventName, event.eventPayload);
    }
}

export function identify(user: Profile) {
    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
        analytics.identify(user.id, {
            email: user.email,
            firstName: user.firstname,
            lastName: user.lastname,
            teamId: user.activeTeam?.id,
            role: user.activeRole?.type,
            tier: user.activeTeam?.tier,
            phone: user.phone,
            createdAt: user.created,
            avatar: user.avatar?.url,
            username: user.email,
            tierId: user.activeTeam?.tierId,
            resellerId: user.activeTeam?.resellerId,
            /** ⬇️⬇️⬇️ June.so special props */
            group_id: user.activeTeam?.id,
            channel: getChannel(),
            stripeCustomerId: user.activeTeam?.stripeCustomerId,
        });
        if (user.activeTeam?.id) {
            analytics.group({
                id: user.activeTeam?.id,
                name: user.activeTeam?.name,
                avatar: user.activeTeam?.teamAvatarURL,
                plan: user.activeTeam?.tier,
                employees: user.activeTeam?.users?.length,
            });
        }
    } else {
        // eslint-disable-next-line no-console
        console.log('Identify Disabled');
    }
}

export const useAnalyticsChannelSetup = () => {
    const [{ inTeams }] = useTeams();

    useEffect(() => {
        if (inTeams) {
            setChannel(Channel.MsTeams);
        }
    }, [inTeams]);
};
