import {
    Contact,
    ContactPropertyType,
    Feature,
    NewContactProperty,
} from '../../../api/types';
import { PhoneConfigurator } from './PropertyConfigurator/PhoneConfigurator';
import { PropertyConfigurator } from './PropertyConfigurator/PropertyConfigurator';
import { propertyTypeIconNameMap } from '../contactPropertyTypeMap';
import { useCreateProperty } from '../../../queries/properties';
import { useEnabledFeature } from '../../../queries/user';
import { ExternalPropertyConfigurator } from './PropertyConfigurator/ExternalPropertyConfigurator';
import { UserPropertyModel } from '../../UserInfoProperty/types';

type Props = {
    selectedProperty: NewContactProperty;
    handlePropertyClean: (type: ContactPropertyType | null) => void;
    handleClose: () => void;
    contact: Partial<Contact>;
    onAddProperty?: (newProp: UserPropertyModel) => void;
};

export const NewPropertyConfiguration = ({
    selectedProperty,
    handlePropertyClean,
    handleClose,
    contact,
    onAddProperty,
}: Props) => {
    const { mutateAsync: createProperty, isPending } = useCreateProperty();
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );

    const handleAdd = (newProperty: UserPropertyModel) => {
        onAddProperty?.(newProperty);
        handleClose();
    };

    if ('source' in selectedProperty) {
        return (
            <ExternalPropertyConfigurator
                propertyName={selectedProperty.name}
                onUpdateBehaviorChanged={(externalUpdateBehavior) => {
                    createProperty({
                        name: selectedProperty.name,
                        type: selectedProperty.type,
                        source: selectedProperty.source,
                        allowExternalSync: true,
                        externalUpdateBehavior,
                        externalId: selectedProperty.externalId,
                        integrationId: selectedProperty.integrationId,
                        externalEntityType: selectedProperty.externalEntityType,
                        options: selectedProperty.options,
                    }).then(handleAdd);
                    handleClose();
                }}
                isPending={isPending}
                onClose={handleClose}
            />
        );
    }

    if (
        selectedProperty.type === ContactPropertyType.Phone &&
        isMultipleContactPhonesEnabled
    ) {
        return <PhoneConfigurator onClose={handleClose} contact={contact} />;
    }

    return (
        <PropertyConfigurator
            type={selectedProperty.type}
            onNameChange={(name) => {
                handlePropertyClean(null);

                createProperty({
                    name,
                    type: selectedProperty.type as ContactPropertyType,
                    icon: propertyTypeIconNameMap[
                        selectedProperty.type as ContactPropertyType
                    ],
                }).then(handleAdd);
            }}
            isPending={isPending}
            onClose={handleClose}
        />
    );
};
