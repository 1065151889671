import { getTitle } from './utils';
import { Contact } from '../../api/types';
import ComplianceProperties from '../../components/ComplianceProperties';
import { usePropertiesQuery } from '../../queries/properties';
import { ContactPropertiesGroup } from './ContactPropertiesGroup';
import { CohortsGroup } from './CohortsGroup';
import { PropertySource } from '../../components/ContactProperty/types';
import { Box } from '@mui/material';
import { propertiesPanel } from './styles';

interface Props {
    contact: Partial<Contact>;
    onCreate?: (c: Contact) => void;
}

export const ContactPropertiesPanel = (props: Props) => {
    const { data: properties = [] } = usePropertiesQuery();

    return (
        <Box sx={propertiesPanel}>
            {props.contact?.id && (
                <ComplianceProperties contact={props.contact} />
            )}

            <ContactPropertiesGroup
                title={getTitle(PropertySource.Clerk)}
                contact={props.contact}
                properties={properties}
                onCreate={props.onCreate}
                canCreateProps
                initiallyExpanded
            />

            {props.contact?.id && <CohortsGroup contact={props.contact} />}
        </Box>
    );
};
