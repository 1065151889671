/* eslint-disable @typescript-eslint/no-explicit-any */
import createClient, { client } from './http';
import { UserPropertyModel } from '../components/UserInfoProperty/types';
import { Column, Contact, ContactPhone, ContactPropertyType } from './types';
import { SlimFilter } from '../components/Contacts/contacts.queries';
import { UUID } from '../types/uuid';

type PropertyUpdateData = {
    id: string;
    params: Partial<UserPropertyModel>;
};

export type PropertyOptionUpdateDTO = {
    type: ContactPropertyType.Select | ContactPropertyType.MultiSelect;
    oldOption: string;
    newOption: string;
};

export type PropertyOptionRemoveDTO = {
    type: ContactPropertyType.Select | ContactPropertyType.MultiSelect;
    option: string;
};

export type CreateCohortDto = {
    isPublic?: boolean;
    name?: string;
    filters?: SlimFilter[];
    included?: string[];
    excluded?: string[];
    base?: {
        cohortId?: number;
        query?: string;
        filters?: SlimFilter[];
        included?: string[];
        excluded?: string[];
    };
};

export type ContactPhoneCreateDto = {
    id: Contact['id'];
    phone: string;
    label: string;
    isPrimary: boolean;
};

export type ContactPhoneUpdateDto = {
    id: Contact['id'];
    phoneId: ContactPhone['id'];
    phone: string;
    label: string;
    isPrimary: boolean;
};

export function deleteContacts({ ids }: { ids: string[] }) {
    return createClient().post(`/contacts/multi-delete`, {
        ids,
    });
}

export function deleteContactsWithFilters(
    filters: CreateCohortDto,
): Promise<{ ids: UUID[] }> {
    return client
        .delete('/contacts', {
            data: filters,
        })
        .then(({ data }) => data);
}

// Attributes
export const listProperties = (): Promise<UserPropertyModel[]> =>
    client.get<UserPropertyModel[]>('/properties').then(({ data }) => data);

export function createProperty(
    params: Partial<UserPropertyModel>,
): Promise<UserPropertyModel> {
    return client.post(`/properties`, params).then((res) => res.data);
}

export function updateProperty({
    id,
    params,
}: PropertyUpdateData): Promise<UserPropertyModel> {
    return createClient()
        .put(`/properties/${id}`, params)
        .then((res) => res.data);
}

export function deleteProperty(id: string) {
    return createClient().delete(`/properties/${id}`);
}

export function updatePropertyOption(
    propertyId: string,
    data: PropertyOptionUpdateDTO,
): Promise<UserPropertyModel> {
    return createClient()
        .put(`/properties/option/${propertyId}`, data)
        .then((res) => res.data);
}

export function deletePropertyOptions(
    propertyId: string,
    data: PropertyOptionRemoveDTO,
) {
    return createClient().delete(`/properties/option/${propertyId}`, { data });
}

export function importCSV({
    file,
    schema,
    isPublic,
    cohortId,
    newCohortName,
}: {
    file: File;
    schema: Omit<Column, 'usage'>[];
    isPublic: boolean;
    cohortId?: number;
    newCohortName?: string;
}) {
    const schemaFile = new Blob([JSON.stringify(schema)], {
        type: 'application/json',
    });

    const formData = new FormData();
    formData.append('dataFiles', file);
    formData.append('schemaFiles', schemaFile);
    formData.append('isPublic', isPublic ? 'true' : 'false');

    if (cohortId) {
        formData.append('cohortId', String(cohortId));
    }

    if (newCohortName) {
        formData.append('newCohortName', newCohortName);
    }

    return client.post<Contact[]>('/contacts-importer', formData);
}

export function exportContactsCSV(filters: CreateCohortDto) {
    return client
        .post(`/v2/contacts/export`, filters, { responseType: 'blob' })
        .then(({ data }) => data);
}

export function contactPhoneCreate({ id, ...data }: ContactPhoneCreateDto) {
    return client
        .post<ContactPhone>(`/contact-phone/contact/${id}`, data)
        .then(({ data }) => data);
}

export function contactPhoneUpdate({
    id,
    phoneId,
    ...data
}: ContactPhoneUpdateDto) {
    return client
        .put<ContactPhone>(`/contact-phone/contact/${id}/${phoneId}`, data)
        .then(({ data }) => data);
}

export function contactPhoneDelete({
    id,
    phoneId,
}: {
    id: Contact['id'];
    phoneId: ContactPhone['id'];
}) {
    return client
        .delete(`/contact-phone/contact/${id}/${phoneId}`)
        .then(({ data }) => data);
}
