import { useParams } from 'react-router';
import IntegrationPropertyMap from '../IntegrationPropertyMap';
import { CsvIntegration } from './CSVIntegration/CsvIntegration';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { Link } from '@mui/material';

export const IntegrationSettings = () => {
    const { integrationId } = useParams<{ integrationId: string }>();

    if (integrationId === 'csv') {
        return <CsvIntegration />;
    }

    return (
        <SettingsLayout
            breadcrumbs={[
                'Workspace',
                <Link
                    key="integrations-link"
                    href="/settings/integrations"
                    variant="body4"
                    color="text.disabled"
                >
                    Integrations
                </Link>,
            ]}
            title="Property mapping"
        >
            <IntegrationPropertyMap />
        </SettingsLayout>
    );
};
