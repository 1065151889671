import { AxiosError } from 'axios';
import head from 'lodash/head';

export const CSV_PARSER_OPTIONS = {
    header: true,
    preview: 1,
};

export const getCsvImportErrorDescription = (error: AxiosError) => {
    const errorData = (
        error as AxiosError<{
            message?: string;
            schemaValidationErrors?: {
                line: number;
                column: string;
                errors: string[];
                value: unknown;
            }[];
            line?: number;
        }>
    )?.response?.data;
    if (
        errorData &&
        'schemaValidationErrors' in errorData &&
        errorData.schemaValidationErrors?.length
    ) {
        const firstError = head(errorData.schemaValidationErrors);
        return [
            `Column '${firstError?.column}' on line #${errorData?.line} has following errors: `,
            ...(firstError?.errors ?? []),
        ];
    }

    return errorData?.message ?? 'Something went wrong, please try again';
};
