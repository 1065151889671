import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { SettingsLayout } from '../../SettingsLayout/SettingsLayout';
import { CustomDownloadIcon } from '../../../icons/custom-icons/CustomDownloadIcon';
import { CsvFilePicker } from './CsvFilePicker';
import { CsvMapping } from './CsvMapping';
import { ListForm } from './ListForm';
import { CsvImportProvider } from './CsvImportContext';
import { CsvImportFooter } from './CsvImportFooter';
import { CsvError } from './CsvError';
import { CsvFilePreview } from './CsvFilePreview';

export const CsvIntegration = () => {
    return (
        <SettingsLayout
            breadcrumbs={[
                'Workspace',
                <Link
                    key="integrations-link"
                    href="/settings/integrations"
                    variant="body4"
                    color="text.disabled"
                >
                    Integrations
                </Link>,
            ]}
            title="Import your CSV"
            description="Choose a file that consist your contacts"
        >
            <CsvImportProvider>
                <Box
                    sx={{
                        height: 1,
                        gap: 6,
                        boxSizing: 'content-box',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Stack
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <div>
                            <Typography
                                variant="body3"
                                color="primary.dark"
                                fontWeight={500}
                            >
                                Upload a file
                            </Typography>
                            <Typography
                                component="p"
                                variant="body4"
                                color="primary.dark"
                            >
                                Download a pre-made template to make the process
                                easier.
                            </Typography>
                        </div>

                        <Button
                            href="https://clerk-static-assets.s3.amazonaws.com/import-contacts-template.csv"
                            target="_blank"
                            color="info"
                            variant="outlined"
                            size="small"
                            startIcon={<CustomDownloadIcon />}
                            download
                        >
                            Template
                        </Button>
                    </Stack>

                    <CsvFilePicker />

                    <Stack
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <CsvFilePreview />
                        <CsvError />
                    </Stack>

                    <CsvMapping />

                    <ListForm />
                </Box>

                <CsvImportFooter />
            </CsvImportProvider>
        </SettingsLayout>
    );
};
