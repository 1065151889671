import React from 'react';
import Loading from '../../../components/Loading/Loading';

const Lazy = React.lazy(() => import('./OptOut'));

export const OptOutPage = () => (
    <React.Suspense fallback={<Loading />}>
        <Lazy />
    </React.Suspense>
);
