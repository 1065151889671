import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
} from '@mui/material';
import { useCsvImportContext } from './CsvImportContext';
import { ToastActions, useToastContext } from '../../toast/reducer';
import { AxiosError } from 'axios';
import { queryClient } from '../../../queries/queryClient';
import { ALL_CONTACTS_KEY, useContactsImport } from '../../../queries/contacts';
import { ButtonDotLoader } from '../../../components/Forms/ButtonDotLoader';
import { useNavigate } from 'react-router';
import { getCsvImportErrorDescription } from './utils';
import { useEnabledFeature } from '../../../queries/user';
import { Feature } from '../../../api/types';
import { pluralize } from '../../../utils/pluralize';

export const CsvImportFooter = () => {
    const isMultipleContactPhonesEnabled = useEnabledFeature(
        Feature.MultipleContactPhones,
    );
    const {
        file,
        columns,
        enabled,
        numOfLines,
        isPrivate,
        toggleIsPrivate,
        clearForm,
        setError,
        cohort,
        newCohortName,
        newPhoneNumberFields,
    } = useCsvImportContext();

    const { mutateAsync: importCSV, isPending } = useContactsImport();
    const { dispatch: dispatchToast } = useToastContext();

    const navigate = useNavigate();

    const handleSave = async () => {
        if (!file) {
            return;
        }

        setError(null);
        const schema = columns.map(({ usage: _, ...rest }) => {
            if (isMultipleContactPhonesEnabled && rest.itemType === 'phone') {
                const field = newPhoneNumberFields.find(
                    (field) => field.id === rest.field,
                );

                return {
                    ...rest,
                    isPrimary: field?.isPrimary ?? false,
                };
            }
            return { ...rest };
        });

        try {
            const { data: contacts } = await importCSV({
                file,
                schema,
                isPublic: !isPrivate,
                cohortId: cohort?.id,
                newCohortName,
            });

            if (contacts.length) {
                dispatchToast({
                    type: ToastActions.ADD,
                    payload: {
                        severity: 'success',
                        title: 'Contacts import',
                        description: `${contacts.length} contacts successfully uploaded`,
                    },
                });
                navigate('/contacts/book');
            } else {
                dispatchToast({
                    type: ToastActions.ADD,
                    payload: {
                        severity: 'warning',
                        title: 'Contacts import',
                        description: 'No contacts found for import',
                    },
                });
            }
        } catch (e) {
            const errorDescription = getCsvImportErrorDescription(
                e as AxiosError,
            );

            const errorNode =
                typeof errorDescription === 'string' ? (
                    errorDescription
                ) : (
                    <Stack>
                        {errorDescription.map((desc) => (
                            <span key={desc}>{desc}</span>
                        ))}
                    </Stack>
                );

            setError(errorNode);

            dispatchToast({
                type: ToastActions.ADD,
                payload: {
                    severity: 'error',
                    title: 'Contacts import error',
                    description: errorNode,
                },
            });
        } finally {
            queryClient.refetchQueries({
                queryKey: [ALL_CONTACTS_KEY],
            });
        }
    };

    return (
        <Box flex={1}>
            <Divider />
            <Stack
                mt={6}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Button
                    size="large"
                    variant="outlined"
                    sx={{ width: 96 }}
                    disabled={!enabled}
                    onClick={() => {
                        clearForm();
                    }}
                >
                    Clear
                </Button>
                <Stack direction="row" alignItems="center" spacing={6}>
                    {enabled && (
                        <FormControlLabel
                            value={isPrivate}
                            onChange={toggleIsPrivate}
                            control={
                                <Switch
                                    checked={
                                        cohort ? !cohort.isPublic : isPrivate
                                    }
                                    size="small"
                                    color="info"
                                    inputProps={{
                                        'aria-label':
                                            'Contact access: Public/Private switch',
                                    }}
                                />
                            }
                            label={
                                <Typography
                                    variant="body3"
                                    color="custom.gray.super"
                                    sx={{
                                        userSelect: 'none',
                                    }}
                                >
                                    Contact access:{' '}
                                    <Typography
                                        color="primary.dark"
                                        variant="body3"
                                    >
                                        {isPrivate ? 'Private' : 'Public'}
                                    </Typography>
                                </Typography>
                            }
                            labelPlacement="start"
                            disabled={!!cohort || !enabled}
                        />
                    )}
                    <Button
                        size="large"
                        color="info"
                        disabled={!enabled || isPending}
                        onClick={handleSave}
                    >
                        {isPending ? (
                            <ButtonDotLoader />
                        ) : (
                            `Upload ${pluralize(numOfLines, 'contact', 'contacts', 'contacts')}`
                        )}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};
